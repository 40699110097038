import React, { useState } from 'react'
import PageHeading from '../../../molecules/PageHeading'
import StatCardsWrapper from '../../../organisms/statistics/StatCardsWrapper'
import ChartSection from '../../../organisms/statistics/ChartSection'
import DownloadableResourcesSection from '../../../organisms/statistics/DownloadableResourcesSection'
import useStatData from '../../../../hooks/useStatData'
import { Tab, TabContext, TabList, TabPanel } from '../../../atoms/tabs'
import GeneralFilters from '../../../organisms/statistics/filters/GeneralFilters'
import useUserPermission from '../../../../hooks/useUserPermission'

const tabStyle = { selected: 'bg-white text-blue-700 font-bold' }

const Statistics = () => {
  const [tabSelected, setTabSelected] = useState('generalTab')
  const { selectedIndicator, changeIndicator } = useStatData()
  const { canDownload } = useUserPermission('statistics')

  const handleTabChange = (_event, newValue) => {
    setTabSelected(newValue)
  }

  const handleShowMoreDetail = indicator => {
    changeIndicator(indicator)
    setTabSelected('indicatorsTab')
  }

  const visibleTabs = [
    { label: 'Resumen general', value: 'generalTab', visible: true },
    { label: 'Indicadores', value: 'indicatorsTab', visible: true },
    { label: 'Descargas', value: 'downloadTab', visible: canDownload }
  ].filter(tab => tab.visible)

  return (
    <>
      <PageHeading title="Estadísticas" pageDescription="Conoce los principales indicadores de uso de la plataforma." />
      <TabContext value={tabSelected}>
        <GeneralFilters />
        <div className="mb-6 border-3">
          <div className="w-full bg-neutral-100">
            <TabList onChange={handleTabChange} className="bg-neutral-100 max-w-lg">
              {visibleTabs.map(tab => (
                <Tab key={tab.value} label={tab.label} value={tab.value} classes={tabStyle} />
              ))}
            </TabList>
          </div>
          <TabPanel value="generalTab">
            <StatCardsWrapper onShowMoreDetail={handleShowMoreDetail} />
          </TabPanel>
          <TabPanel value="indicatorsTab">
            <ChartSection selectedIndicator={selectedIndicator} changeIndicator={handleShowMoreDetail} />
          </TabPanel>
          {canDownload && (
            <TabPanel value="downloadTab">
              <DownloadableResourcesSection />
            </TabPanel>
          )}
        </div>
      </TabContext>
    </>
  )
}
export default Statistics
