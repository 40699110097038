import React from 'react'
import PropTypes from 'prop-types'
import FieldCheckboxTitle from '../../../molecules/FieldCheckboxTitle'
import { fieldTitle } from '../../../../configs/templatesConfig'
import FieldTitle from '../../../molecules/FieldTitle'

const name = 'unique_response_per_document'
const UniqueResponsePerDocument = ({ config }) => {
  const { title, hint } = fieldTitle(name, config.unique_response_per_document)
  return (
    <>
      {config.unique_response_per_document === 'official_decide' ? (
        <FieldCheckboxTitle name={name} label={title} description={hint} />
      ) : (
        <FieldTitle title={title} hint={hint} />
      )}
    </>
  )
}

export default UniqueResponsePerDocument

UniqueResponsePerDocument.propTypes = {
  config: PropTypes.object
}
