import { FormControl, FormLabel } from '@material-ui/core'
import React from 'react'
import IconSelectorField from '../../molecules/fields/IconSelectorField'
import { notificationIcons } from '../../../configs/notificationsConfig'

const NotificationTypeField = () => {
  return (
    <FormControl component="fieldset">
      <FormLabel classes={{ root: 'block font-bold mb-3 min-w-100 text-gray-800 text-base' }} component="legend">
        Tipo de notificación
      </FormLabel>
      <IconSelectorField options={notificationIcons} name="icon" />
    </FormControl>
  )
}

export default NotificationTypeField
