import React from 'react'
import Container from '../../../atoms/Container'
import NotificationForm from '../../../organisms/notifications/NotificationForm'
import { headerInfo } from '../../../../configs/notificationsConfig'
import useNotification from '../../../../hooks/useNotification'

import { PageNavigation } from '../../../organisms/navigation/PageNavigation'

const NotificationNew = () => {
  const { createNotification } = useNotification()

  return (
    <>
      <PageNavigation />
      <Container className="py-16">
        <NotificationForm headerInfo={headerInfo} initialValues={{}} mutateForm={createNotification} />
      </Container>
    </>
  )
}
export default NotificationNew
