import React from 'react'
import RolesFilter from '../../filters/RolesFilter'
import UbigeoFieldFilter from '../../../molecules/fields/UbigeoFieldFilter'
import InstitutionTypeFieldFilter from '../../../molecules/fields/InstitutionTypeFieldFilter'
import useUserPermission from '../../../../hooks/useUserPermission'

const UsersFilters = () => {
  const { canSeeInstitutionFilter } = useUserPermission('statistics')

  return (
    <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
      <div>
        <RolesFilter label="Perfil" name="user_role" />
      </div>
      {canSeeInstitutionFilter && (
        <>
          <div>
            <InstitutionTypeFieldFilter name="user" />
          </div>
          <div>
            <UbigeoFieldFilter name="user" />
          </div>
        </>
      )}
    </div>
  )
}

export default UsersFilters
