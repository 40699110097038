import PropTypes from 'prop-types'
import React from 'react'
import { TagBox } from '../../molecules/TagBox'
import { Link } from 'react-router-dom'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { IconButton } from '@material-ui/core'
import {
  errorResendInfo,
  hasResend,
  useAnswerToWebhook,
  webhookStatusColorMap
} from '../../../hooks/useAnswerToWebhook'
import WebhookStatusLabel from '../../molecules/WebhookStatusLabel'
import WebhookResendButton from '../../molecules/buttons/WebhookResendButton'
import DownloadPDFButton from './DownloadPDFButton'
import { paymentMethods } from '../../../configs/paymentMethodConfigs'
import Tooltip from '../../atoms/Tooltip'
import Tr from '../../atoms/table/Tr'
import Td from '../../atoms/table/Td'
import { Button } from '../../atoms/Button'
import { KeyboardArrowRight } from '@material-ui/icons'

const AnswerRow = ({ answer: answerFromServer, form, formHasCost }) => {
  const { answer, onResend } = useAnswerToWebhook({ answer: answerFromServer, formId: form.id })

  return (
    <Tr ariaDescribedby={`loading-${answer.id}`} ariaBusy={answer.webhook_status === 'sending'}>
      <Td className="py-4">
        <p>
          <span className="font-bold">ID: </span>
          {answer.id}
        </p>
        <p>
          <span className="font-bold">Código: </span>
          {answer.hashid}
        </p>
        {answer.document_number && (
          <p>
            <span className="font-bold">{answer.doc_type_name}: </span>
            {answer.document_number}
          </p>
        )}
        {answer.applicant_name && (
          <p>
            <span className="font-bold">Nombre: </span>
            {answer.applicant_name}
          </p>
        )}
      </Td>
      <Td>{answer.confirmed_at}</Td>
      {form.has_webhook && (
        <Td className={webhookStatusColorMap[answer.webhook_status]}>
          <div className="flex items-center font-semibold gap-0.5">
            <WebhookStatusLabel
              status={answer.webhook_status}
              id={`loading-${answer.id}`}
              infoContent={errorResendInfo.list}
            />
          </div>
        </Td>
      )}
      {formHasCost ? (
        answer.payment_status ? (
          <Td>
            {paymentMethods[answer.selected_kind_payment].name} (
            {paymentMethods[answer.selected_kind_payment].states[answer.payment_status]})
          </Td>
        ) : (
          <Td />
        )
      ) : null}
      {form.subtype === 'assessment' && <Td>{answer.score}</Td>}
      <Td>
        <TagBox label={answer.status.label} />
      </Td>
      <Td>
        <div className="flex justify-between">
          <div>
            <Tooltip title={`Ver respuesta`}>
              <IconButton
                className="text-blue-700"
                aria-label={`Ver respuesta`}
                component={Link}
                to={`/formularios/${form.id}/respuestas/${answer.id}`}
                role="link"
              >
                <VisibilityIcon />
              </IconButton>
            </Tooltip>
            <DownloadPDFButton formId={form.id} answerId={answer.id} />
            {form.has_webhook && hasResend(answer.webhook_status) && (
              <WebhookResendButton onResend={onResend} status={answer.webhook_status} size="medium" />
            )}
          </div>
        </div>
      </Td>
      <Td className="bg-indigo-400 relative w-10">
        <Button
          variant="link"
          el={Link}
          className="px-0 absolute h-full inset-0 max-w-10 w-10 min-w-0"
          size="full"
          to={`/formularios/${form.id}/respuestas/${answer.id}`}
          aria-label={`Ver respuesta ${answer.id}`}
          role="link"
        >
          <KeyboardArrowRight className="w-7 text-black" />
        </Button>
      </Td>
    </Tr>
  )
}

export default AnswerRow

AnswerRow.propTypes = {
  answer: PropTypes.object,
  form: PropTypes.object,
  formHasCost: PropTypes.bool
}
