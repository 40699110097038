import PropTypes from 'prop-types'
import React from 'react'

const StickyNoteIcon = ({ className, svgClassName, ariaLabel }) => (
  <svg
    width="42"
    height="42"
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={svgClassName}
    aria-label={ariaLabel}
  >
    <path
      d="M33.25 33.25V17.5H24.5V8.75H8.75V33.25H33.25ZM33.25 36.75H8.75C6.825 36.75 5.25 35.175 5.25 33.25V8.75C5.25 6.825 6.825 5.25 8.75 5.25H26.25L36.75 15.75V33.25C36.75 35.175 35.175 36.75 33.25 36.75ZM21 17.5H12.25V21H21V17.5ZM29.75 24.5H12.25V28H29.75V24.5Z"
      className={className}
    />
  </svg>
)

export default StickyNoteIcon

StickyNoteIcon.propTypes = {
  className: PropTypes.string,
  svgClassName: PropTypes.string,
  ariaLabel: PropTypes.string
}
