import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from '../atoms/Button'
import ExpandMore from '../atoms/icons/ExpandMore'
import { Menu, MenuItem } from '@material-ui/core'
import clsx from 'clsx'

const SelectButton = ({ options = [], mainOption, optionButtonName, id }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClickMenu = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <div className="flex justify-between items-center gap-1">
        <Button
          className="flex mb-4 md:mb-2 w-full md:max-w-56 rounded-r-none focus:outline-focusOutline"
          size="full"
          variant="secondary"
          onClick={mainOption.onClick}
          disabled={mainOption.disabled}
        >
          {mainOption.name}
        </Button>
        <Button
          className={clsx(
            'flex mb-4 md:mb-2 w-11 max-w-11 min-w-11 rounded-l-none focus:outline-focusOutline',
            Boolean(anchorEl) && 'outline-focusOutline'
          )}
          size="full"
          variant="secondary"
          aria-haspopup="menu"
          aria-label={optionButtonName}
          id={`${id}Button`}
          aria-controls={id}
          onClick={handleClickMenu}
        >
          <ExpandMore />
        </Button>
      </div>
      <Menu
        id={id}
        aria-labelledby={`${id}Button`}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={{
          paper: 'rounded-lg'
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: -55,
          horizontal: 'right'
        }}
      >
        {options.map(option => (
          <MenuItem onClick={option.onClick} key={option.name} aria-label={option.name} disabled={option.disabled}>
            {option.shortName || option.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default SelectButton

SelectButton.propTypes = {
  options: PropTypes.array,
  mainOption: PropTypes.object,
  optionButtonName: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired
}
