import PropTypes from 'prop-types'
import React from 'react'
import TextField from '../../molecules/fields/TextField'
import * as validations from '../../../utils/validations'
import FormSection from '../../molecules/FormSection'
import CheckboxField from '../../molecules/fields/CheckboxField'
import { options } from '../../../configs/notificationsConfig'
import RadioField from '../../molecules/fields/RadioField'
import Value from '../../molecules/Value'
import DateField from '../../molecules/fields/DateField'
import TimePickerField from '../../molecules/fields/TimePickerField'
import Error from '../../molecules/Error'
import { requiredArray } from '../../../utils/validations'
import { FormControl, FormLabel } from '@material-ui/core'

const NotificationFormConfiguration = ({ onSendTypeChange }) => {
  return (
    <FormSection title="Configuración" className="js-field-container">
      <FormControl component="fieldset">
        <FormLabel classes={{ root: 'block font-bold mb-1 min-w-100 js-field-container text-gray-800 text-base' }}>
          Funcionarios que recibirán la notificación
        </FormLabel>
        {options.checkboxPermissions.map(permission => (
          <CheckboxField
            key={permission.code}
            margin="none"
            name="permissions"
            value={permission.code}
            label={permission.pluralName}
            validate={requiredArray('Seleccione al menos una opción', 1)}
            hideError
          />
        ))}
        <Error name="permissions" />
      </FormControl>
      <RadioField
        containerClassName="mt-6 mb-2 w-56"
        margin="none"
        name="send_type"
        label="Hora de envío"
        options={options.validation}
        validate={validations.required()}
        onChange={e => {
          onSendTypeChange(e === 'scheduled' ? 'Programar envío' : 'Enviar notificación')
        }}
      />
      <Value name="send_type">
        {({ value: sendType }) =>
          sendType === 'scheduled' && (
            <div className="flex items-center ml-8">
              <DateField
                placeholder="Ingresar fecha"
                name="date"
                size="2xl"
                containerClassName="-mb-0 min-w-64 mr-2"
                validate={validations.required()}
              />
              <TimePickerField
                emptyLabel="Selecciona una hora"
                name="hour"
                className="sm:max-w-sm"
                validate={validations.required()}
              />
            </div>
          )
        }
      </Value>
      <TextField
        rootClassName="mt-6"
        name="url_destination"
        label="URL destino"
        hint="Ingresa la URL de la página a donde redireccionará la notificación."
        size="2xl"
        validate={validations.mix(validations.required(), validations.isURL())}
      />
    </FormSection>
  )
}

export default NotificationFormConfiguration

NotificationFormConfiguration.propTypes = {
  onSendTypeChange: PropTypes.func
}
