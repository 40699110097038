import React from 'react'
import PropTypes from 'prop-types'
import * as validations from '../../../../utils/validations'
import TextField from '../../../molecules/fields/TextField'

const FormName = ({ disabled, label = 'Nombre del formulario', hint }) => {
  return (
    <TextField
      name="name"
      label={label}
      hint={hint}
      margin="none"
      size="2xl"
      validate={validations.required()}
      disabled={disabled}
      capsLockInfo
    />
  )
}

export default FormName

FormName.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  hint: PropTypes.string
}
