import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import IconButton from '@material-ui/core/IconButton'
import HighlightOff from '@material-ui/icons/HighlightOff'
import clsx from 'clsx'
import Tooltip from '../../atoms/Tooltip'
import useNotification from '../../../hooks/useNotification'
import { getRolDetails } from '../../../configs/usersConfig'
import EditButton from '../../molecules/buttons/EditButton'
import Table from '../../atoms/table/Table'
import Tr from '../../atoms/table/Tr'
import Th from '../../atoms/table/Th'
import Td from '../../atoms/table/Td'
import NotificationIconViewer from './NotificationIconViewer'

const NotificationsTable = ({ notifications = [] }) => {
  const { destroyNotification, invalidateNotifications } = useNotification()
  const handleDestroy = async notification => {
    if (window.confirm('¿Estás seguro que deseas cancelar el envío de la notificación?')) {
      destroyNotification(notification.id, {
        onSuccess: () => invalidateNotifications()
      })
    }
  }
  const showActions = notification => {
    return (
      <Td className="w-1/2 p-0">
        {!notification.was_sent && (
          <>
            <EditButton to={`/notificaciones/${notification.id}/editar`} className="md:p-0" />
            <Tooltip title="Cancelar envío">
              <IconButton
                color="primary"
                aria-label="Eliminar"
                type="button"
                onClick={() => handleDestroy(notification)}
              >
                <HighlightOff />
              </IconButton>
            </Tooltip>
          </>
        )}
      </Td>
    )
  }

  return (
    <div className="overflow-x-scroll md:overflow-x-auto">
      <Table
        className="table-fixed md:w-full w-800"
        values={notifications}
        head={
          <Tr body={false}>
            <Th className="w-1/12">Ícono</Th>
            <Th className="w-3/12">Contenido</Th>
            <Th className="w-2/12">Destinatarios</Th>
            <Th className="w-2/12">Fecha de envío</Th>
            <Th className="w-2/12">Estado</Th>
            <Th className="w-2/12">Acciones</Th>
          </Tr>
        }
        body={notifications?.map((notification, index) => {
          return (
            <Tr key={notification.id} className="align-middle">
              <Td>
                <NotificationIconViewer value={notification.icon} />
              </Td>
              <Td className="text-lg">
                <div
                  className="hint-html-injected break-words"
                  dangerouslySetInnerHTML={{ __html: notification.text }}
                />
              </Td>
              <Td className="text-lg">
                {notification.permissions?.map((option, index) => (
                  <Fragment key={index}>
                    {getRolDetails(option).name} <br />
                  </Fragment>
                ))}
              </Td>
              <Td className="text-lg">
                <label className={clsx('font-bold', notification.was_sent ? 'text-green-700' : 'text-yellow-700')}>
                  {notification.was_sent ? 'Enviado el:' : 'Programado para:'}
                </label>
                <br />
                {notification.date_send_at}
                {notification.hour_send_at && ` - ${notification.hour_send_at}`}
              </Td>
              <Td>
                <div>
                  <p>Abiertos: {notification.statistics.opened}</p>
                  <p>Ignorados: {notification.statistics.ignored}</p>
                  <p className="font-bold">Total: {notification.statistics.total}</p>
                </div>
              </Td>
              {showActions(notification)}
            </Tr>
          )
        })}
      />
    </div>
  )
}

export default NotificationsTable

NotificationsTable.propTypes = {
  notifications: PropTypes.array
}
