import PropTypes from 'prop-types'
import React, { useState } from 'react'
import * as validations from '../../../utils/validations'
import { useDropzone } from 'react-dropzone'
import { useField } from 'react-final-form'
import clsx from 'clsx'
import Error from '../Error'
import { DropZoneBox } from '../DropZoneBox'
import {
  humanFileSize,
  findMimeTypeAndExtensionMatch,
  getAcceptedExtensionsFormat
} from '../../../configs/fileTypesConfig'
import { marginSizes } from '../../../configs/sizesConfig'
import ErrorValidationMessage from '../ErrorValidationMessage'
import DeleteButton from '../buttons/DeleteButton'
import ImageCrop from './file/ImageCrop'

const FileField = ({
  name,
  payload,
  index,
  required,
  className,
  extensions,
  margin = 'normal',
  fileBackground = 'bg-white',
  onChange,
  hideError = false,
  hideFileUrl = false,
  ariaLabel,
  validate,
  showFileTypeValidation,
  hasImageCrop = false,
  imageCropProps
}) => {
  const { input } = useField(name, { validate: validations.mix(validate, required && validations.required()) })
  const [isDraggingOver, setIsDraggingOver] = useState(false)
  const [showDropZoneBox, setShowDropZoneBox] = useState(true)

  const onDrop = (acceptedFile, rejectedFile) => {
    setIsDraggingOver(false)
    const uploadFile = acceptedFile[0] || rejectedFile[0]

    const selectedFile = uploadFile.errors ? uploadFile.file : uploadFile
    const MimeTypeAndExtensionMatch = findMimeTypeAndExtensionMatch(extensions, selectedFile)
    if (showFileTypeValidation) {
      selectedFile.isValid = MimeTypeAndExtensionMatch
      input.onChange(selectedFile)
      onChange && onChange(selectedFile)
    } else if (MimeTypeAndExtensionMatch) {
      input.onChange(selectedFile)
      onChange && onChange(selectedFile)
    }
  }
  const dropzoneConfig = {
    onDrop,
    multiple: false,
    onDragOver: () => setIsDraggingOver(true),
    onDragLeave: () => setIsDraggingOver(false)
  }

  if (extensions) dropzoneConfig.accept = getAcceptedExtensionsFormat(extensions)

  const { getRootProps, getInputProps } = useDropzone(dropzoneConfig)

  const handleDelete = () => {
    setShowDropZoneBox(true)
    input.onChange()
  }

  return (
    <div className={clsx(marginSizes[margin], 'relative', className, 'js-field-container')}>
      {payload?.label && (
        <label
          aria-describedby={`input-file-${index}-${name}-hint`}
          className="text-lg font-bold"
          htmlFor={`input-file-${index}-${name}`}
        >
          {payload?.label}
        </label>
      )}
      {payload?.hint && (
        <p className="mb-2" id={`input-file-${index}-${name}-hint`}>
          {payload?.hint}
        </p>
      )}
      {showDropZoneBox && (
        <DropZoneBox
          getRootProps={getRootProps}
          getInputProps={getInputProps}
          index={index}
          name={name}
          isHovering={isDraggingOver}
          ariaLabel={ariaLabel}
        />
      )}
      {hasImageCrop && input.value && (
        <ImageCrop image={input.value} onLoadValidImage={el => setShowDropZoneBox(!el)} {...imageCropProps} />
      )}
      {input.value && (
        <div className={clsx(fileBackground, 'rounded-md p-4 mt-4 flex justify-between items-center pr-4')}>
          <div>
            {input.value.url && !hideFileUrl ? (
              <a href={input.value.url} target="_blank" rel="noreferrer">
                {input.value.name} - {humanFileSize(input.value.size)}
              </a>
            ) : (
              <p>
                {input.value?.name} - {humanFileSize(input.value?.size)}
              </p>
            )}
            {showFileTypeValidation && !input.value.saved && !input.value.isValid && (
              <ErrorValidationMessage error="El archivo seleccionado no cumple con el formato solicitado." />
            )}
          </div>
          <DeleteButton onClick={handleDelete} />
        </div>
      )}
      {!hideError && (
        <div className="relative">
          <Error name={name} touched={false} />
        </div>
      )}
    </div>
  )
}

export default FileField

FileField.propTypes = {
  className: PropTypes.string,
  extensions: PropTypes.array,
  fileBackground: PropTypes.string,
  hideError: PropTypes.bool,
  hideFileUrl: PropTypes.bool,
  index: PropTypes.number,
  margin: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  payload: PropTypes.object,
  required: PropTypes.bool,
  ariaLabel: PropTypes.string,
  validate: PropTypes.func,
  showFileTypeValidation: PropTypes.bool,
  hasImageCrop: PropTypes.bool,
  imageCropProps: PropTypes.object
}
