import general from './permissions/general'
import menu from './permissions/menu'
import form from './permissions/form'
import quickSearch from './permissions/quickSearch'
import area from './permissions/area'
import user from './permissions/user'
import feedback from './permissions/feedback'
import payment from './permissions/payment'
import procedureManagement from './permissions/procedureManagement'
import breadcrumb from './permissions/breadcrumb'
import announcement from './permissions/announcement'
import statistics from './permissions/statistics'

const useUserPermission = type => {
  const userPermissionByType = {
    menu: menu(),
    form: form(),
    quickSearch: quickSearch(),
    procedureManagement: procedureManagement(),
    user: user(),
    area: area(),
    feedback: feedback(),
    payment: payment(),
    breadcrumb: breadcrumb(),
    announcement: announcement(),
    statistics: statistics()
  }[type]

  return { ...general(), ...userPermissionByType }
}
export default useUserPermission
