import React from 'react'
import TextField from '../../molecules/fields/TextField'
import * as validations from '../../../utils/validations'
import * as validationsAdmin from '../../../utils/validationsAdmin'
import { HtmlField } from '../../molecules/fields/HtmlField'
import FormSection from '../../molecules/FormSection'
import CheckboxField from '../../molecules/fields/CheckboxField'
import PreviewModal from './PreviewModal'
import Value from '../../molecules/Value'
import NotificationTypeField from './NotificationTypeField'

const NotificationFormContent = () => {
  return (
    <>
      <FormSection title="Contenido" className="-mb-0 md:-mb-0">
        <NotificationTypeField />
        <HtmlField
          name="text"
          isBasic
          label="Texto de la notificación"
          hint="Escribe la notificación. Considera que el texto debe ser corto para que sea legible como una notificación. Ej.: Participa en la próxima
capacitación sobre Facilita."
          maxContentLength={80}
          validate={validationsAdmin.isRichTextFieldEmpty()}
        />
      </FormSection>
      <div className="flex justify-end items-center mb-6 md:mb-0 md:mt-0 bg-blue-400 p-3 pr-8 md:pr-16 text-blue-700">
        <PreviewModal />
      </div>
      <FormSection>
        <div className="mb-3">
          <label className="font-bold text-xl md:text-2xl">
            Enviar correo <span className="text-sm font-normal">(opcional)</span>{' '}
          </label>
        </div>
        <CheckboxField margin="none" name="has_email" label="Quiero enviar un correo junto con la notificación." />
        <Value name="has_email">
          {({ value: hasEmail }) =>
            hasEmail && (
              <div className="p-4">
                <div className="bg-neutral-200 py-2 px-7">
                  <TextField
                    rootClassName="mt-6"
                    name="email_subject"
                    label="Asunto del correo"
                    size="2xl"
                    validate={validations.required()}
                  />
                  <HtmlField
                    name="email_content"
                    label="Contenido del correo"
                    validate={validations.mix(validations.required(), validationsAdmin.isRichTextFieldEmpty())}
                  />
                </div>
              </div>
            )
          }
        </Value>
      </FormSection>
    </>
  )
}

export default NotificationFormContent
