import React from 'react'
import FacebookIcon from '../../atoms/icons/FacebookIcon'
import TwitterIcon from '../../atoms/icons/TwitterIcon'
import FooterLinks from '../../molecules/footer/FooterLinks'
import FooterHeader from '../../molecules/footer/FooterHeader'
import Container from '../../atoms/Container'
import FooterWrapper from '../../molecules/footer/FooterWrapper'

const PublicFooter = () => {
  const listPeruvianState = [
    { href: 'https://www.gob.pe/estado', text: 'El Estado Peruano' },
    { href: 'https://www.gob.pe/55008-que-es-facilita-peru', text: '¿Qué es Facilita Perú?' },
    {
      href: 'https://www.gob.pe/10899-solicitar-acceso-a-facilita-peru-plataforma-integral-de-solicitudes-digitales-del-estado-peruano',
      text: 'Solicita acceso a Facilita Perú'
    }
  ]
  const listNationalDirectory = [
    { href: 'https://transparencia.gob.pe/redes/redessociales.html', text: 'Directorio Nacional de Redes Sociales' },
    { href: 'https://www.gob.pe/establecimientosdesalud', text: 'Directorio de Establecimientos de Salud' }
  ]
  const listSocialMedia = [
    {
      href: 'https://www.facebook.com/PeruPaisDigital/',
      text: 'Facebook Secretaría de Gobierno y Transformación Digital',
      icon: <FacebookIcon />
    },
    {
      href: 'https://twitter.com/PeruPaisDigital',
      text: 'Twitter Secretaría de Gobierno y Transformación Digital',
      icon: <TwitterIcon />
    }
  ]

  return (
    <FooterWrapper>
      <Container className="md:px-36 py-12 px-8">
        <div className="container md:flex md:flex-row md:px-8 justify-between">
          <div className="mb-6 w-1/2">
            <FooterHeader>Sobre el Estado Peruano</FooterHeader>
            <FooterLinks list={listPeruvianState} />
          </div>
          <div className="mt-10 md:mt-0 w-1/2">
            <div>
              <FooterHeader>Directorios nacionales</FooterHeader>
              <FooterLinks list={listNationalDirectory} />
            </div>
            <div className="mt-9">
              <FooterHeader>Síguenos</FooterHeader>
              <ul className="flex items-center">
                {listSocialMedia.map(item => (
                  <li key={item.href} className="mr-6">
                    <a aria-label={item.text} href={item.href} rel="noreferrer" target="_blank">
                      {item.icon}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </Container>
    </FooterWrapper>
  )
}

export default PublicFooter
