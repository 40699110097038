import PropTypes from 'prop-types'
import React from 'react'
import TextField from '../../molecules/fields/TextField'
import * as validations from '../../../utils/validations'
import useTextValidation from '../../../hooks/useTextValidation'

const ParagraphCitizenField = ({ index, name, payload, required, disabled = false, margin }) => {
  const label = index ? `${index}. ${payload?.label}` : payload?.label
  const maxLength = window.sisoli.paragraphLimits[payload?.limit] || 500
  const translationText = useTextValidation()
  return (
    <TextField
      label={label}
      name={name}
      hint={payload?.hint}
      element="textarea"
      size="full"
      rows={6}
      disabled={disabled}
      maxLength={maxLength}
      validate={validations.mix(
        validations.maxLength(maxLength, translationText.maxLenght(maxLength)),
        required && validations.required(translationText.required)
      )}
      margin={margin}
      capsLockInfo
    />
  )
}

export default ParagraphCitizenField

ParagraphCitizenField.propTypes = {
  disabled: PropTypes.bool,
  index: PropTypes.number,
  margin: PropTypes.string,
  name: PropTypes.string,
  payload: PropTypes.object,
  required: PropTypes.bool
}
