import PropTypes from 'prop-types'
import React from 'react'
import QuestionOptionsSection from './QuestionOptionsSection'
import TextField from '../../molecules/fields/TextField'

export const FullNameField = ({ baseName, disabled, labelInitialValue = 'Nombres y apellidos' }) => {
  return (
    <div className="grid md:grid gap-4">
      <div>
        <TextField
          label="Etiqueta de la pregunta"
          name={`${baseName}.payload.label`}
          initialValue={labelInitialValue}
          margin="none"
          disabled={disabled}
          size="2xl"
          capsLockInfo
        />
        <QuestionOptionsSection baseName={baseName} size="2xl" />
        <TextField
          label="Etiqueta de la pregunta"
          name={`${baseName}.payload.name_label`}
          initialValue="Nombres"
          disabled
          size="2xl"
        />
        <TextField
          label="Etiqueta de la pregunta"
          name={`${baseName}.payload.father_last_name_label`}
          initialValue="Apellido paterno"
          disabled
          size="2xl"
        />
        <TextField
          label="Etiqueta de la pregunta"
          name={`${baseName}.payload.mother_last_name_label`}
          initialValue="Apellido materno"
          margin="none"
          disabled
          size="2xl"
        />
      </div>
    </div>
  )
}

FullNameField.propTypes = {
  baseName: PropTypes.string,
  disabled: PropTypes.bool,
  labelInitialValue: PropTypes.string
}
