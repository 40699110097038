import { useState, useEffect, useCallback } from 'react'

const EVENT_KEY_DOWN = 'keydown'
const EVENT_KEY_UP = 'keyup'

export const useCapLocks = ({ evaluate }) => {
  const [isCapLocksActive, setIsCapLocksActive] = useState(false)

  const wasCapsLockActivated = useCallback(event => {
    if (event.getModifierState && event.getModifierState('CapsLock')) {
      setIsCapLocksActive(true)
    }
  }, [])

  const wasCapsLockDeactivated = useCallback(event => {
    if (event.getModifierState && !event.getModifierState('CapsLock')) {
      setIsCapLocksActive(false)
    }
  }, [])

  useEffect(() => {
    if (evaluate) {
      document.addEventListener(EVENT_KEY_DOWN, wasCapsLockActivated)
      return () => {
        document.removeEventListener(EVENT_KEY_DOWN, wasCapsLockActivated)
      }
    }
  }, [evaluate, wasCapsLockActivated])

  useEffect(() => {
    if (evaluate) {
      document.addEventListener(EVENT_KEY_UP, wasCapsLockDeactivated)
      return () => {
        document.removeEventListener(EVENT_KEY_UP, wasCapsLockDeactivated)
      }
    }
  }, [evaluate, wasCapsLockDeactivated])

  return isCapLocksActive
}
