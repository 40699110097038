import React from 'react'
import FileField from '../../../molecules/fields/FileField'
import * as validations from '../../../../utils/validations'

const extensions = ['jpg', 'png', 'jpeg']

const CoverImage = () => {
  const validation = validations.asyncMix(
    validations.isValidFileMimeType(['images']),
    validations.maxFileSize(2, 'MB'),
    validations.minImageDimensions(1200, 200)
  )

  return (
    <div>
      <label
        aria-describedby={`input-file-${null}-cover_image-hint`}
        className="mb-2 font-bold block"
        htmlFor={`input-file-${null}-cover_image`}
      >
        Imagen de portada
      </label>
      <FileField
        name="cover_image"
        ariaLabel="Selecciona la imagen de portada"
        extensions={extensions}
        validate={validation}
        showFileTypeValidation
        hasImageCrop
        imageCropProps={{
          minWidth: 1200,
          minHeight: 200,
          maxWidth: 1500,
          maxHeight: 600
        }}
        index={null}
        payload={{
          hint: (
            <>
              <span className="mb-2 inline-block">
                Agrega una imagen representativa del formulario, solo como acompañamiento visual. Puedes incluir el
                título, logos o elementos visuales relacionados con el tema (ej.: si el formulario es para el préstamo
                de libros de la biblioteca municipal, puedes agregar una imagen de libros). Toda información relevante
                debe estar en el campo “Indicación para llenar el formulario”.
              </span>
              <br />
              <span>
                Formatos aceptados: JPG, JPEG o PNG. Tamaño: mínimo de 1200 px de ancho por 200 px de alto, y máximo de
                1500 px de ancho por 600 px de alto. Peso máximo: 2 MB.
              </span>
            </>
          )
        }}
      />
    </div>
  )
}

export default CoverImage
