import PropTypes from 'prop-types'
import * as validations from '../../../utils/validations'
import React from 'react'
import DateCalendarField from './DateCalendarField'
import useFieldValue from '../../../hooks/useFieldValue'
import { dateStringToObject } from '../../../utils/helpers'
import clsx from 'clsx'

const DateRangeField = ({ name, label, labelClassName, isHorizontal, margin = 'dense', minimumDate, validate }) => {
  const fromFieldName = `${name}_from`
  const toFieldName = `${name}_to`
  const fromValue = useFieldValue(fromFieldName)
  const toValue = useFieldValue(toFieldName)
  const fromPickerValue = dateStringToObject(fromValue || minimumDate)
  const toPickerValue = dateStringToObject(toValue)
  const startDate = dateStringToObject(minimumDate)

  return (
    <fieldset className="mb-3">
      <legend className="text-base font-bold text-gray-800">{label}</legend>
      <div className={clsx('mt-2', isHorizontal && 'flex flex-row gap-4', labelClassName)}>
        <DateCalendarField
          name={fromFieldName}
          maximumDate={toPickerValue}
          minimumDate={startDate}
          placeholder="Desde"
          margin={margin}
          validate={validations.mix(
            validate,
            validations.isDate(),
            startDate && validations.isMinDate(new Date(startDate.year, startDate.month - 1, startDate.day)),
            toPickerValue &&
              validations.isMaxDate(new Date(toPickerValue.year, toPickerValue.month - 1, toPickerValue.day))
          )}
          size="2xl"
        />
        <DateCalendarField
          name={toFieldName}
          minimumDate={fromPickerValue}
          placeholder="Hasta"
          margin={margin}
          validate={validations.mix(
            validate,
            validations.isDate(),
            fromPickerValue &&
              validations.isMinDate(new Date(fromPickerValue.year, fromPickerValue.month - 1, fromPickerValue.day))
          )}
          size="2xl"
        />
      </div>
    </fieldset>
  )
}

export default DateRangeField

DateRangeField.propTypes = {
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  margin: PropTypes.string,
  name: PropTypes.string,
  minimumDate: PropTypes.string,
  isHorizontal: PropTypes.bool,
  validate: PropTypes.func
}
