import React from 'react'
import PropTypes from 'prop-types'
import * as validationsAdmin from '../../../../utils/validationsAdmin'
import { HtmlField } from '../../../molecules/fields/HtmlField'

const FormHint = ({ label, hint, placeholder }) => {
  return (
    <HtmlField
      name="hint"
      label={label}
      placeholder={placeholder}
      hint={hint}
      validate={validationsAdmin.isRichTextFieldEmpty()}
      maxContentLength={1000}
      margin="none"
      capsLockInfo
    />
  )
}

export default FormHint

FormHint.propTypes = {
  label: PropTypes.string,
  hint: PropTypes.string,
  placeholder: PropTypes.string
}
