import React from 'react'
import PropTypes from 'prop-types'
import { ReactSVG as Svg } from 'react-svg'
import { getIcon, getNotificationIcon } from '../../../configs/notificationsConfig'

const NotificationIconViewer = ({ value }) => {
  if (getIcon(value)) {
    return (
      <Svg src={getIcon(value)} aria-label="" wrapper="span" className="h-5 md:h-8 w-auto md:w-8 m-auto opacity-50" />
    )
  }

  const IconSvg = getNotificationIcon(value)?.IconSvg
  if (IconSvg) return <IconSvg className="fill-gray-650 opacity-50" svgClassName="w-10 h-10" />

  return null
}

export default NotificationIconViewer

NotificationIconViewer.propTypes = {
  value: PropTypes.string
}
