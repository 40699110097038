import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import ScrollToTop from '../../molecules/ScrollToTop'
import SaveButton from '../../molecules/buttons/SaveButton'
import { Form as FinalForm } from 'react-final-form'
import { focusOnFirstError } from '../../../utils/decorators'
import FormsHeader from '../../molecules/FormsHeader'
import { FORM_ERROR } from 'final-form'
import { useHistory } from 'react-router-dom'
import NotificationFormContent from './NotificationFormContent'
import NotificationFormConfiguration from './NotificationFormConfiguration'
import { Button } from '../../atoms/Button'

const formSteps = {
  CONFIGURATION: {
    label: 'Configuración',
    title: 'Configuración de la notificación'
  },
  CONTENT: {
    label: 'Contenido',
    title: 'Contenido de la notificación'
  }
}

const NotificationForm = ({ headerInfo, initialValues, mutateForm }) => {
  const history = useHistory()
  const [uploadProgress, setUploadProgress] = useState(null)
  const [step, setStep] = useState(0)
  const [submitTextButton, setSubmitTextButton] = useState(
    initialValues.send_type === 'scheduled' ? 'Programar envío' : 'Enviar notificación'
  )
  const stepPages = [
    <NotificationFormConfiguration onSendTypeChange={setSubmitTextButton} key="0" />,
    <NotificationFormContent key="1" />
  ]
  const stepLabels = Object.values(formSteps)
  const isFirstStep = step === 0
  const lastStepIndex = stepPages.length - 1
  const isLastStep = lastStepIndex === step

  const handleSubmit = values =>
    isLastStep ? convertToSubmit(values) : setStep(() => Math.min(step + 1, lastStepIndex))
  const onPrevClick = () => setStep(() => Math.max(step - 1, 0))

  const convertToSubmit = async values => {
    try {
      await mutateForm(
        values,
        {
          onSuccess: () =>
            submitTextButton === 'Programar envío'
              ? history.push(`/notificaciones?action=notification_created&notification_sort=created_at desc`)
              : history.push(`/notificaciones?action=notification_send&notification_sort=created_at desc`),
          onError: error => window.alert(error.response.data.message || 'Hubo un error al actualizar la notificación')
        },
        {
          onUploadProgress: progressEvent =>
            setUploadProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total))
        }
      )
    } catch (error) {
      if (error.response.data.error) {
        return { [FORM_ERROR]: error.response.data.error }
      }
    }
  }

  useEffect(() => {
    let timer
    if (uploadProgress === 100) timer = setTimeout(() => setUploadProgress(null), 1000)
    return () => timer && clearTimeout(timer)
  }, [uploadProgress])

  return (
    <>
      <FormsHeader
        title={headerInfo.title}
        hint={headerInfo.hint}
        steps={stepLabels}
        activeStep={stepLabels[step]}
        classHint="mb-5 md:mb-8"
      />
      <FinalForm onSubmit={handleSubmit} initialValues={initialValues} decorators={[focusOnFirstError]}>
        {({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            {stepPages[step]}
            <div className="flex gap-6 justify-end px-6 md:px-0 mb-6 flex-col md:flex-row">
              {!isFirstStep && (
                <Button
                  className="md:max-w-56 mb-4 md:mb-0"
                  type="button"
                  onClick={onPrevClick}
                  size="full"
                  variant="secondary"
                >
                  Regresar
                </Button>
              )}
              <div className="w-full md:max-w-72 justify-end">
                <SaveButton
                  label={isLastStep ? submitTextButton : 'Continuar'}
                  disabled={submitting}
                  uploadProgress={uploadProgress}
                />
              </div>
            </div>
            <ScrollToTop trigger={step} />
          </form>
        )}
      </FinalForm>
    </>
  )
}
export default NotificationForm

NotificationForm.propTypes = {
  headerInfo: PropTypes.object,
  initialValues: PropTypes.object,
  mutateForm: PropTypes.func.isRequired
}
