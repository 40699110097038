import React from 'react'
import FieldsSeparator from '../../atoms/FieldsSeparator'
import TextField from '../../molecules/fields/TextField'
import * as validations from '../../../utils/validations'
import availableQuestionTypes from '../../../configs/questionTypesTranslateConfig'
import PropTypes from 'prop-types'
import { HtmlField } from '../../molecules/fields/HtmlField'

const QuestionRenderer = ({ question, questionTranslated, baseName }) => {
  const QuestionComponent = availableQuestionTypes[question.kind] || TextField

  return (
    <>
      <QuestionComponent baseName={baseName} question={question} questionTranslated={questionTranslated} />
      {question.payload.has_hint && (
        <>
          <FieldsSeparator />
          <div className="w-full">
            {question.kind === 'file' ? (
              <HtmlField
                label="Descripción"
                margin="minimum"
                name={`${baseName}.payload.hint`}
                hint={question?.payload.hint}
                capsLockInfo
              />
            ) : (
              <TextField
                label="Descripción"
                size="full"
                name={`${baseName}.payload.hint`}
                validate={validations.required()}
                margin="none"
                className="h-12"
                rootClassName="flex-1 min-w-48"
                errorClassName="relative inset-0"
                hint={question?.payload.hint}
                placeholder="Ingresa la traducción."
                capsLockInfo
              />
            )}
          </div>
        </>
      )}
    </>
  )
}

export default QuestionRenderer
QuestionRenderer.propTypes = {
  baseName: PropTypes.string,
  question: PropTypes.object,
  questionTranslated: PropTypes.object
}
