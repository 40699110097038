import PropTypes from 'prop-types'
import React from 'react'
import TextField from '../../molecules/fields/TextField'
import * as validations from '../../../utils/validations'
import UbigeoCitizenField from '../question_fields/UbigeoCitizenField'
import useTextValidation from '../../../hooks/useTextValidation'

const AddressCitizenField = ({ index = null, name, payload, required, disabled = false }) => {
  const translationText = useTextValidation()
  return (
    <>
      <UbigeoCitizenField
        index={index}
        payload={payload}
        name={`${name}.ubigeo`}
        required={required}
        disabled={disabled}
        margin="dense"
      />
      <TextField
        name={`${name}.address`}
        label="Dirección"
        size="2xl"
        disabled={disabled}
        rootClassName="md:mb-8"
        validate={required && validations.required(translationText.required)}
        capsLockInfo
      />
    </>
  )
}

export default AddressCitizenField

AddressCitizenField.propTypes = {
  disabled: PropTypes.bool,
  index: PropTypes.number,
  name: PropTypes.string,
  payload: PropTypes.object,
  required: PropTypes.bool
}
