import React from 'react'
import PropTypes from 'prop-types'
import FieldTitle from '../../../molecules/FieldTitle'
import { fieldTitle } from '../../../../configs/templatesConfig'
import Value from '../../../molecules/Value'
import InfoAutoResponseFromTemplate from '../InfoAutoResponseFromTemplate'
import { HtmlField } from '../../../molecules/fields/HtmlField'
import * as validations from '../../../../utils/validations'
import useFieldValue from '../../../../hooks/useFieldValue'
import FieldCheckboxTitle from '../../../molecules/FieldCheckboxTitle'

const autoResponseName = 'auto_response'
const autoResponseTextName = 'auto_response_text'

const AutoResponse = ({ config }) => {
  const templateId = useFieldValue('template_id')
  const showInfoAutoResponseFromTemplate = config.auto_response === 'official_decide' && templateId
  const { title, hint } = fieldTitle(autoResponseName, config.auto_response)
  return (
    <>
      {config.auto_response === 'official_decide' ? (
        <FieldCheckboxTitle name={autoResponseName} label={title} description={hint} />
      ) : (
        <FieldTitle title={title} hint={hint} />
      )}
      {showInfoAutoResponseFromTemplate && <InfoAutoResponseFromTemplate />}
      <Value name={autoResponseName}>
        {({ value: autoResponse }) =>
          autoResponse && (
            <HtmlField
              name={autoResponseTextName}
              label="Texto de la respuesta"
              hasMaxContentLength={false}
              allowScrolling
              className="ml-7"
              editorClassName="sm:max-w-xl"
              validate={validations.required()}
              capsLockInfo
            />
          )
        }
      </Value>
    </>
  )
}

export default AutoResponse

AutoResponse.propTypes = {
  config: PropTypes.object
}
