import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import * as validations from '../../../utils/validations'
import TextField from '../../molecules/fields/TextField'
import { Field } from 'react-final-form'

const CheckboxesField = ({ baseName, question, questionTranslated }) => {
  return (
    <>
      <div className="flex items-baseline flex-wrap">
        <TextField
          label="Etiqueta de la pregunta"
          size="full"
          name={`${baseName}.payload.label`}
          validate={validations.required()}
          margin="minimun"
          rootClassName="flex-1 min-w-48"
          hint={question.payload.label}
          placeholder="Ingresa la traducción."
          capsLockInfo
        />
      </div>

      <hr className="h-0.5 bg-neutral-400 my-4" />
      <h3 className="font-bold mb-4">Alternativas</h3>
      <div className="overflow-y-auto max-h-80">
        {question.payload.alternatives.map((alternative, index) => (
          <Fragment key={alternative.id}>
            {alternative.type !== 'other' && (
              <div className="flex items-baseline flex-wrap mr-8">
                <TextField
                  name={`${baseName}.payload.alternatives[${index}].label`}
                  placeholder="Ingresa la traducción."
                  initialValue={questionTranslated?.payload.alternatives[index] || ''}
                  hint={alternative.label}
                  rootClassName="w-full"
                  size="full"
                  validate={validations.required()}
                  capsLockInfo
                />
                <Field
                  component="input"
                  className="hidden"
                  name={`${baseName}.payload.alternatives[${index}].id`}
                  initialValue={alternative.id}
                />
              </div>
            )}
          </Fragment>
        ))}
      </div>
    </>
  )
}

export default CheckboxesField

CheckboxesField.propTypes = {
  baseName: PropTypes.string,
  question: PropTypes.object,
  questionTranslated: PropTypes.object
}
