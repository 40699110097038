import React from 'react'
import Container from '../../../atoms/Container'
import NotificationForm from '../../../organisms/notifications/NotificationForm'
import { useParams } from 'react-router-dom'
import { headerInfoEdit } from '../../../../configs/notificationsConfig'
import useNotification from '../../../../hooks/useNotification'

import { PageNavigation } from '../../../organisms/navigation/PageNavigation'

const NotificationEdit = () => {
  const { updateNotification, getNotification } = useNotification()
  const { id } = useParams()
  const { data: notificationData, status } = getNotification(id)

  if (status !== 'success') return null
  return (
    <>
      <PageNavigation />
      <Container className="py-16">
        <NotificationForm
          headerInfo={headerInfoEdit}
          initialValues={notificationData}
          mutateForm={updateNotification}
        />
      </Container>
    </>
  )
}
export default NotificationEdit
