import PropTypes from 'prop-types'
import React from 'react'
import TextField from '../../molecules/fields/TextField'
import Alert from '../../molecules/Alert'
import QuestionOptionsSection from './QuestionOptionsSection'
import RadioField from '../../molecules/fields/RadioField'
import WhenFieldChanges from '../../molecules/WhenFieldChanges'
import useFieldValue from '../../../hooks/useFieldValue'
import { isJuridica, personTypeOptions } from '../../../configs/personTypesConfig'
import * as validations from '../../../utils/validations'

const DocIdDocTypeField = ({
  baseName,
  docTypeInitialValue = 'Tipo de documento de identidad',
  docIdInitialValue = 'Número de documento de identidad',
  disableEditLabels = false
}) => {
  const personType = useFieldValue(`${baseName}.payload.person_type`)
  return (
    <div className="grid md:grid-cols-2 gap-4">
      <div className="mb-4 md:mb-0">
        <RadioField
          label={'Selecciona el tipo de solicitante'}
          name={`${baseName}.payload.person_type`}
          options={personTypeOptions}
          validate={validations.required()}
        />
        {!isJuridica(personType) && (
          <>
            <TextField
              label="Etiqueta de la pregunta"
              name={`${baseName}.payload.doc_type_label`}
              initialValue={docTypeInitialValue}
              margin="none"
              size="2xl"
              disabled={disableEditLabels}
              capsLockInfo
            />
            <QuestionOptionsSection baseName={baseName} size="2xl" />
          </>
        )}
        <WhenFieldChanges
          field={`${baseName}.payload.person_type`}
          set={`${baseName}.payload.doc_id_label`}
          to={isJuridica(personType) ? 'Ingresa el número de RUC' : docIdInitialValue}
        />
        <TextField
          label="Etiqueta de la pregunta"
          name={`${baseName}.payload.doc_id_label`}
          initialValue={docIdInitialValue}
          margin="dense"
          size="2xl"
          disabled={disableEditLabels}
          capsLockInfo
        />
        {isJuridica(personType) && <QuestionOptionsSection baseName={baseName} />}
      </div>
      <Alert type="info">Se validará el formato del número según el tipo de documento que la persona seleccione.</Alert>
    </div>
  )
}

export default DocIdDocTypeField

DocIdDocTypeField.propTypes = {
  baseName: PropTypes.string,
  disableEditLabels: PropTypes.bool,
  docIdInitialValue: PropTypes.string,
  docTypeInitialValue: PropTypes.string
}
