import React from 'react'
import { Form } from 'react-final-form'
import { focusOnFirstError } from '../../../utils/decorators'
import TextField from '../../molecules/fields/TextField'
import * as validations from '../../../utils/validations'
import * as validationsAdmin from '../../../utils/validationsAdmin'
import { HtmlField } from '../../molecules/fields/HtmlField'
import { Button } from '../../atoms/Button'
import { Link, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import StepsAndQuestions from '../form_translations/StepsAndQuestions'
import FieldsSeparator from '../../atoms/FieldsSeparator'

const FormsFormTranslation = ({ onSubmit, initialValues, formDataInSpanish, formData = [], isProcedureManagement }) => {
  const { id: formId, procedureManagementId } = useParams()
  const backLink = isProcedureManagement
    ? `/gestiones-de-tramites/${procedureManagementId}/traducciones`
    : `/formularios/${formId}/traducciones`
  return (
    <Form
      onSubmit={onSubmit}
      decorators={[focusOnFirstError]}
      initialValues={initialValues}
      render={({ handleSubmit, form }) => (
        <form onSubmit={handleSubmit}>
          <div className="py-6">
            <TextField
              className="rounded h-12"
              labelClassName="md:mb-0"
              name="name"
              hint={formDataInSpanish?.name}
              label="Título"
              margin="none"
              size="2xl"
              placeholder="Ingresa la traducción."
              validate={validations.required()}
              initialValue={formData?.name || ''}
              capsLockInfo
            />
          </div>
          {formDataInSpanish?.hint && (
            <div>
              <HtmlField
                className="md:mb-0"
                editorClassName="rounded"
                name="hint"
                label="Indicación para llenar el formulario"
                hint={formDataInSpanish?.hint}
                placeholder="Ingresa la traducción."
                maxContentLength={1000}
                validate={validationsAdmin.isRichTextFieldEmpty()}
                initialValue={formData?.hint || ''}
                capsLockInfo
              />
            </div>
          )}

          {formDataInSpanish?.auto_response_text && (
            <div>
              <HtmlField
                editorClassName="rounded"
                name="auto_response_text"
                label="Información para el ciudadano después de enviado el formulario"
                placeholder="Ingresa la traducción."
                hint={formDataInSpanish?.auto_response_text}
                validate={validationsAdmin.isRichTextFieldEmpty()}
                maxContentLength={600}
                initialValue={formData?.auto_response_text || ''}
                capsLockInfo
              />
            </div>
          )}
          <FieldsSeparator />
          <h2 className="font-bold text-xl mb-6 mt-8">Preguntas del formulario</h2>
          <StepsAndQuestions formData={formData} formDataInSpanish={formDataInSpanish} form={form} />

          <div className="flex flex-col-reverse md:flex-row justify-end py-4 px-4 md:px-0">
            <Button el={Link} to={backLink} variant="secondary" className="md:w-52 h-13 md:mr-6" size="full">
              Regresar
            </Button>
            <Button type="submit" className="md:w-52 h-13 mb-3 md:mb-0" size="full">
              Guardar traducción
            </Button>
          </div>
        </form>
      )}
    />
  )
}

FormsFormTranslation.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.func.isRequired,
  formDataInSpanish: PropTypes.object,
  formData: PropTypes.object,
  isProcedureManagement: PropTypes.bool.isRequired
}

FormsFormTranslation.defaultProps = {
  formData: {}
}

export default FormsFormTranslation
