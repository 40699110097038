import PropTypes from 'prop-types'
import React from 'react'
import { Field } from 'react-final-form'
import TextField from '../../molecules/fields/TextField'
import { useTranslation } from 'react-i18next'
import HiddenField from './HiddenField'

export const FullNameApplicantField = ({ baseName, question }) => {
  const { t } = useTranslation('form')

  const renderTextField = (name, hint, initialValue) => (
    <Field name={name}>
      {({ input }) => {
        if (input.value === '') {
          input.onChange(initialValue)
        }
        return (
          <TextField
            label="Etiqueta de la pregunta"
            hint={hint}
            {...input}
            margin="none"
            disabled
            size="full"
            className="mb-4"
            capsLockInfo
          />
        )
      }}
    </Field>
  )

  return (
    <div className="grid md:grid gap-4">
      <div>
        {renderTextField(
          `${baseName}.payload.label`,
          'Nombres y apellidos',
          t('form_label_applicant_full_name') || 'Nombres y apellidos'
        )}
        {renderTextField(`${baseName}.payload.name_label`, 'Nombres', t('form_label_names') || 'Nombres')}
        {renderTextField(
          `${baseName}.payload.father_last_name_label`,
          'Apellido paterno',
          t('form_label_father_last_name') || 'Apellido paterno'
        )}
        {renderTextField(
          `${baseName}.payload.mother_last_name_label`,
          'Apellido materno',
          t('form_label_mother_last_name') || 'Apellido materno'
        )}

        <HiddenField name={`${baseName}.payload.has_hint`} value={question.payload.has_hint} />
        <HiddenField name={`${baseName}.payload.has_indication_text`} value={question.payload.has_indication_text} />
      </div>
    </div>
  )
}

FullNameApplicantField.propTypes = {
  baseName: PropTypes.string,
  question: PropTypes.object
}
