import PropTypes from 'prop-types'
import React from 'react'
import QuestionOptionsSection from './QuestionOptionsSection'
import TextField from '../../molecules/fields/TextField'
import RadioField from '../../molecules/fields/RadioField'
import Alert from '../../molecules/Alert'
import Value from '../../molecules/Value'
import WhenFieldChanges from '../../molecules/WhenFieldChanges'
import * as validations from '../../../utils/validations'
import formValidations from '../../../utils/formValidations'
import useFieldValue from '../../../hooks/useFieldValue'

const options = [
  {
    value: 'email',
    label: 'Correo electrónico',
    citizenLabel: 'Correo electrónico de contacto (debes tener acceso al correo)'
  },
  {
    value: 'cellphone',
    label: 'Teléfono celular',
    citizenLabel: 'Teléfono celular del contacto (debes tener acceso al teléfono)'
  },
  {
    value: 'email_cellphone',
    label: 'Correo electrónico o teléfono celular (a elección del ciudadano)',
    citizenLabel: 'Selecciona tu medio de contacto preferido'
  }
]

const WhenFieldChangesContactMedium = ({ contactMedium, baseName, options }) => (
  <WhenFieldChanges
    field={`${baseName}.payload.contact_medium`}
    set={`${baseName}.payload.label`}
    to={options.find(o => o.value === contactMedium).citizenLabel}
    condition={value => value === contactMedium}
  />
)

export const EmailPhoneApplicantField = ({ baseName }) => {
  const hasDisableConfirmation = useFieldValue(`${baseName}.disable_confirmation`)

  return (
    <div className="grid md:grid-cols-2 gap-4">
      <div>
        <RadioField
          label="Selecciona el medio de contacto que deseas del solicitante"
          name={`${baseName}.payload.contact_medium`}
          options={options}
          validate={validations.mix(validations.required(), formValidations.isEmailPhoneApplicantWithAutomaticAnswer())}
        />
        {options.map((option, index) => (
          <WhenFieldChangesContactMedium
            contactMedium={option.value}
            baseName={baseName}
            options={options}
            key={index}
          />
        ))}
        <Value name={`${baseName}.payload.contact_medium`}>
          {({ value: contactMedium }) => {
            if (!contactMedium) return null
            return (
              <TextField
                label="Etiqueta de la pregunta"
                name={`${baseName}.payload.label`}
                margin="none"
                disabled
                initialValue={options.find(o => o.value === contactMedium).citizenLabel}
                size="4xl"
                capsLockInfo
              />
            )
          }}
        </Value>
        <QuestionOptionsSection baseName={baseName} margin="none" size="4xl" />
      </div>
      {!hasDisableConfirmation && (
        <Alert type="info">
          Se enviará un código de validación al correo o teléfono de la persona, el cual deberá ingresar antes de enviar
          sus respuestas.
        </Alert>
      )}
    </div>
  )
}

WhenFieldChangesContactMedium.propTypes = {
  baseName: PropTypes.string,
  contactMedium: PropTypes.string,
  options: PropTypes.array
}

EmailPhoneApplicantField.propTypes = {
  baseName: PropTypes.string
}
