import PropTypes from 'prop-types'
import React, { useState } from 'react'
import Container from '../../atoms/Container'
import { options, quickSearchSteps, headerInfo } from '../../../configs/quickSearchesConfig'
import FormSection from '../../molecules/FormSection'
import FileField from '../../molecules/fields/FileField'
import FieldsSeparator from '../../atoms/FieldsSeparator'
import TextField from '../../molecules/fields/TextField'
import * as validations from '../../../utils/validations'
import { HtmlField } from '../../molecules/fields/HtmlField'
import SelectField from '../../molecules/fields/SelectField'
import Value from '../../molecules/Value'
import RadioField from '../../molecules/fields/RadioField'
import antispam from '../../../../assets/images/antispam.svg'
import PrivacyPolicyCreator from '../forms/PrivacyPolicyCreator'
import { Form as FinalForm } from 'react-final-form'
import { clearMutator } from '../../../utils/formMutators'
import { focusOnFirstError, preventSubmitPrivacyPolicy } from '../../../utils/decorators'
import SaveButton from '../../molecules/buttons/SaveButton'
import FormsHeader from '../../molecules/FormsHeader'
import { useHistory } from 'react-router-dom'
import { serialize } from 'object-to-formdata'
import WhenFieldChanges from '../../molecules/WhenFieldChanges'
import { PageNavigation } from '../navigation/PageNavigation'
import PublishSchedule from './PublishSchedule'

const extensions = ['jpg', 'png', 'bmp']

const QueryPageForm = ({ initialValues, linkSteps, action, mutate }) => {
  const history = useHistory()
  const [uploadProgress, setUploadProgress] = useState(null)
  const headerInfoText = headerInfo(initialValues?.name)[action]

  const handleSubmit = values => {
    const formData = serialize(values, { indices: true })
    const config = {
      onUploadProgress: progressEvent =>
        setUploadProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total)),
      onSuccess: ({ id }) => history.push(`/consultas-rapidas/${id || values.id}/resultados?action=${action}`)
    }
    try {
      mutate(values.id, formData, config)
    } catch (error) {
      alert('Ocurrió un error al intentar guardar.')
    }
  }

  return (
    <>
      <PageNavigation />
      <Container className="py-12">
        <FinalForm
          initialValues={initialValues}
          onSubmit={handleSubmit}
          mutators={{ clear: clearMutator }}
          decorators={[focusOnFirstError, preventSubmitPrivacyPolicy]}
        >
          {({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit}>
              <>
                <FormsHeader
                  title={headerInfoText.title}
                  hint={headerInfoText.hint}
                  steps={quickSearchSteps}
                  activeStep={quickSearchSteps[0]}
                  linkSteps={linkSteps}
                />
                <FormSection title="Información general">
                  <p className="font-bold mb-1">Logo (opcional)</p>
                  <p className="mb-2">
                    La imagen no debe pesar más de 1 MB. Se recomienda 150 pixeles de alto x 450 de ancho. Los formatos
                    permitidos son JPG, PNG y BMP.
                  </p>
                  <FileField name="logo" ariaLabel="Logo" extensions={extensions} />
                  <FieldsSeparator />
                  <TextField
                    name="name"
                    label="Título"
                    hint="Escribe el título que tendrá tu consulta."
                    size="2xl"
                    validate={validations.required()}
                  />
                  <TextField
                    name="hint"
                    label="Descripción"
                    hint="Indica a las personas qué información recibirán cuando realicen su consulta. El texto debe ser claro y breve.​"
                    size="full"
                    validate={validations.required()}
                  />
                  <HtmlField
                    name="informative_text"
                    label="Texto informativo de la página de consulta"
                    hint="Es la información que se le dará al ciudadano sobre la consulta que va realizar."
                    validate={validations.required()}
                  />
                </FormSection>
                <FormSection title="Configuración">
                  <div className="grid md:gap-6 md:grid-cols-2">
                    <SelectField
                      name="document_type"
                      label="Documento o código para consulta"
                      hint="Selecciona el tipo de documento o código que la persona debe ingresar para hacer la consulta."
                      options={options.documentType}
                      size="full"
                      validate={validations.required()}
                    />
                    <WhenFieldChanges field="document_type" set="other_document_label" to={null} />
                    <Value name="document_type">
                      {({ value }) =>
                        value === 'other_document' && (
                          <TextField
                            name="other_document_label"
                            hint={
                              <>
                                <span className="font-bold">Etiqueta del tipo de documento.</span> Ejm: Código de
                                expediente
                              </>
                            }
                            validate={validations.required()}
                            size="full"
                            rootClassName="self-end"
                          />
                        )
                      }
                    </Value>
                  </div>
                  <RadioField
                    name="validation_type"
                    label="Dato de validación"
                    hint="Si lo consideras necesario, puedes usar la fecha de nacimiento de la persona como dato de validación. Para ello, deberás incluir esa información en la base de datos que cargarás en el paso 2."
                    options={options.validation}
                    defaultValue={options.validation[0].value}
                  />
                  <FieldsSeparator />
                  <PublishSchedule />
                </FormSection>
                <FormSection>
                  <p className="font-bold mt-6">Protección antispam</p>
                  <p className="mb-2" id="">
                    Este sistema de protección, llamado CAPTCHA, aparecerá de manera predeterminada y brindará
                    protección integral contra el spam y entradas remotas sospechosas. Ejemplo de visualización:
                  </p>
                  <img alt="Antispam" src={antispam} />
                  <FieldsSeparator />
                  <PrivacyPolicyCreator required={false} serviceType="quick_search" />
                </FormSection>
                <div className="w-full flex justify-end">
                  <SaveButton
                    disabled={submitting}
                    containerClassName="w-full"
                    label={'Guardar y continuar'}
                    uploadProgress={uploadProgress}
                  />
                </div>
              </>
            </form>
          )}
        </FinalForm>
      </Container>
    </>
  )
}
export default QueryPageForm

QueryPageForm.propTypes = {
  action: PropTypes.string,
  initialValues: PropTypes.object,
  mutate: PropTypes.func.isRequired,
  linkSteps: PropTypes.array
}
