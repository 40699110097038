import PropTypes from 'prop-types'
import React from 'react'
import AutocompleteField from '../fields/AutocompleteField'
import { dataToOptions } from '../../../utils/helpers'

const AutocompleteFieldFilter = ({
  options,
  defaultOptionLabel,
  name,
  label,
  ariaLabel,
  status,
  boxClassName,
  onChange,
  ...props
}) => {
  const defaultOption = {
    label: defaultOptionLabel,
    value: '',
    className: 'border-b-2 border-gray-600 pb-2 w-full'
  }
  options = defaultOptionLabel ? [defaultOption, ...dataToOptions(options)] : dataToOptions(options)
  if (status !== 'success') return null
  return (
    <AutocompleteField
      options={options || []}
      name={name}
      label={label}
      size="2xl"
      renderOption={option => <span className={option.className}>{option.label}</span>}
      boxClassName={boxClassName}
      onChange={onChange}
      ariaLabel={ariaLabel}
      {...props}
    />
  )
}

export default AutocompleteFieldFilter

AutocompleteFieldFilter.propTypes = {
  boxClassName: PropTypes.string,
  defaultOptionLabel: PropTypes.string,
  label: PropTypes.string,
  ariaLabel: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array,
  status: PropTypes.string
}
