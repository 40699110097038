import useHasRolePermissions from '../useHasRolePermissions'
import { roles } from '../../configs/permissionsConfig'
import { pcmId } from '../useInstitution'
import { useAuth } from '../../components/molecules/Auth'

const { admin, coordinator, branch_coordinator: branchCoordinator } = roles

const Statistics = () => {
  const { user } = useAuth()
  const hasRolePermissions = useHasRolePermissions()

  const canDownload = hasRolePermissions([admin, branchCoordinator, coordinator])

  const canSeeInstitutionFilter = hasRolePermissions([admin])

  const canSeeAdminFilter = user?.institution_id === pcmId

  return {
    canDownload,
    canSeeInstitutionFilter,
    canSeeAdminFilter
  }
}

export default Statistics
