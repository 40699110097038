import PropTypes from 'prop-types'
import React from 'react'

const CampaignIcon = ({ className, svgClassName, ariaLabel }) => (
  <svg
    width="42"
    height="42"
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={svgClassName}
    aria-label={ariaLabel}
  >
    <path
      d="M31.5 19.25C31.5 20.4225 31.5 21.5775 31.5 22.75C33.6 22.75 36.33 22.75 38.5 22.75C38.5 21.5775 38.5 20.4225 38.5 19.25C36.33 19.25 33.6 19.25 31.5 19.25Z"
      className={className}
    />
    <path
      d="M28 30.8175C29.68 32.06 31.8675 33.705 33.6 35C34.3 34.0725 35 33.1275 35.7 32.2C33.9675 30.905 31.78 29.26 30.1 28C29.4 28.945 28.7 29.89 28 30.8175Z"
      className={className}
    />
    <path
      d="M35.7 9.8C35 8.8725 34.3 7.9275 33.6 7C31.8675 8.295 29.68 9.94 28 11.2C28.7 12.1275 29.4 13.0725 30.1 14C31.78 12.74 33.9675 11.1125 35.7 9.8Z"
      className={className}
    />
    <path
      d="M7 15.75C5.075 15.75 3.5 17.325 3.5 19.25V22.75C3.5 24.675 5.075 26.25 7 26.25H8.75V33.25H12.25V26.25H14L22.75 31.5V10.5L14 15.75H7ZM15.8025 18.7425L19.25 16.6775V25.3225L15.8025 23.2575L14.9625 22.75H7V19.25H14.9625L15.8025 18.7425Z"
      className={className}
    />
    <path
      d="M27.125 21C27.125 18.6725 26.11 16.5725 24.5 15.1375V26.845C26.11 25.4275 27.125 23.3275 27.125 21Z"
      className={className}
    />
  </svg>
)

export default CampaignIcon

CampaignIcon.propTypes = {
  className: PropTypes.string,
  svgClassName: PropTypes.string,
  ariaLabel: PropTypes.string
}
