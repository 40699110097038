import PropTypes from 'prop-types'
import React from 'react'

const AlertIcon = ({ className, svgClassName, ariaLabel }) => (
  <svg
    width="42"
    height="42"
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={svgClassName}
    aria-label={ariaLabel}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.12202 31.7152C4.78022 32.2828 5.19705 33 5.86878 33H35.1312C35.8029 33 36.2198 32.2829 35.878 31.7152L21.2468 7.41821C20.911 6.8606 20.089 6.86059 19.7532 7.41821L5.12202 31.7152ZM20.5 12.8716L10.43 29.594H30.57L20.5 12.8716Z"
      className={className}
    />
    <path
      d="M21.2809 17.2179L21.0293 24.784H19.1995L18.9403 17.2179H21.2809ZM18.8793 27.1264C18.8793 26.8071 18.9911 26.5426 19.2148 26.3331C19.4435 26.1186 19.7434 26.0113 20.1144 26.0113C20.4905 26.0113 20.7904 26.1186 21.0141 26.3331C21.2377 26.5426 21.3495 26.8071 21.3495 27.1264C21.3495 27.4357 21.2377 27.6976 21.0141 27.9122C20.7904 28.1267 20.4905 28.234 20.1144 28.234C19.7434 28.234 19.4435 28.1267 19.2148 27.9122C18.9911 27.6976 18.8793 27.4357 18.8793 27.1264Z"
      className={className}
    />
  </svg>
)

export default AlertIcon

AlertIcon.propTypes = {
  className: PropTypes.string,
  svgClassName: PropTypes.string,
  ariaLabel: PropTypes.string
}
