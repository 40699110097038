import PropTypes from 'prop-types'
import React from 'react'
import clsx from 'clsx'
import TextField from '../../molecules/fields/TextField'
import * as validations from '../../../utils/validations'
import { marginSizes } from '../../../configs/sizesConfig'
import Hint from '../../atoms/Hint'
import useTextValidation from '../../../hooks/useTextValidation'

export const FullNameCitizenField = ({ name, index, payload, disabled, required, margin = 'normal' }) => {
  const label = index ? `${index}. ${payload?.label}` : payload?.label
  const nameLabel = payload?.name_label
  const fatherLastNameLabel = payload?.father_last_name_label
  const motherLastNameLabel = payload?.mother_last_name_label
  const translationText = useTextValidation()
  return (
    <div className={clsx(marginSizes[margin], 'relative js-field-container')}>
      {label && (
        <label
          className="block font-bold mb-3"
          id={`${name}-label`}
          htmlFor={`${name}-input`}
          aria-describedby={`${name}-hint`}
        >
          {label}
        </label>
      )}
      {payload?.hint && <Hint hint={payload.hint} name={name} />}
      <div className="flex flex-col md:flex-row md:gap-6 items-start">
        <TextField
          name={`${name}.name`}
          label={nameLabel}
          size="full"
          validate={required && validations.required(translationText.required)}
          disabled={disabled}
          rootClassName="w-full md:w-72 md:mb-0"
          capsLockInfo
        />
        <TextField
          name={`${name}.father_last_name`}
          label={fatherLastNameLabel}
          size="full"
          validate={required && validations.required(translationText.required)}
          disabled={disabled}
          rootClassName="w-full md:w-72 md:mb-0"
          capsLockInfo
        />
        <TextField
          name={`${name}.mother_last_name`}
          label={motherLastNameLabel}
          size="full"
          validate={required && validations.required(translationText.required)}
          disabled={disabled}
          rootClassName="w-full md:w-72 md:mb-0"
          capsLockInfo
        />
      </div>
    </div>
  )
}

FullNameCitizenField.propTypes = {
  disabled: PropTypes.bool,
  index: PropTypes.number,
  margin: PropTypes.string,
  name: PropTypes.string,
  payload: PropTypes.object,
  required: PropTypes.bool
}
