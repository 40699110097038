import PropTypes from 'prop-types'
import React from 'react'
import TextField from '../../molecules/fields/TextField'
import QuestionOptionsSection from './QuestionOptionsSection'

const ShortQuestionField = ({ baseName, validate }) => {
  return (
    <>
      <TextField
        label="Etiqueta de la pregunta"
        size="full"
        name={`${baseName}.payload.label`}
        validate={validate}
        margin="none"
        errorClassName="relative inset-0"
        initialValue=""
        capsLockInfo
      />
      <QuestionOptionsSection baseName={baseName} margin="none" size="full" />
    </>
  )
}

export default ShortQuestionField

ShortQuestionField.propTypes = {
  baseName: PropTypes.string,
  validate: PropTypes.func
}
