import PropTypes from 'prop-types'
import React from 'react'

const LightbulbIcon = ({ className, svgClassName, ariaLabel }) => (
  <svg
    width="42"
    height="42"
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={svgClassName}
    aria-label={ariaLabel}
  >
    <path
      d="M15.75 36.75C15.75 37.7125 16.5375 38.5 17.5 38.5H24.5C25.4625 38.5 26.25 37.7125 26.25 36.75V35H15.75V36.75ZM21 3.5C14.245 3.5 8.75 8.995 8.75 15.75C8.75 19.915 10.8325 23.5725 14 25.795V29.75C14 30.7125 14.7875 31.5 15.75 31.5H26.25C27.2125 31.5 28 30.7125 28 29.75V25.795C31.1675 23.5725 33.25 19.915 33.25 15.75C33.25 8.995 27.755 3.5 21 3.5ZM25.9875 22.925L24.5 23.975V28H17.5V23.975L16.0125 22.925C13.65 21.28 12.25 18.6025 12.25 15.75C12.25 10.92 16.17 7 21 7C25.83 7 29.75 10.92 29.75 15.75C29.75 18.6025 28.35 21.28 25.9875 22.925Z"
      className={className}
    />
  </svg>
)

export default LightbulbIcon

LightbulbIcon.propTypes = {
  className: PropTypes.string,
  svgClassName: PropTypes.string,
  ariaLabel: PropTypes.string
}
