import PropTypes from 'prop-types'
import React, { useState } from 'react'
import clsx from 'clsx'
import { DateField as DateFieldAria, DateInput, DateSegment, I18nProvider, Label } from 'react-aria-components'
import { CalendarDate } from '@internationalized/date'

const DateInputField = ({ placeholder, minimumDate, maximumDate, onChange, onDelete, dateObject }) => {
  const [inputFocused, setInputFocused] = useState(false)
  const inputAriaValue = dateObject ? new CalendarDate(dateObject.year, dateObject.month, dateObject.day) : undefined
  const minAriaDate = minimumDate && new CalendarDate(minimumDate.year, minimumDate.month, minimumDate.day)
  const maxAriaDate = maximumDate && new CalendarDate(maximumDate.year, maximumDate.month, maximumDate.day)

  return (
    <I18nProvider locale="es-PE">
      <DateFieldAria
        shouldForceLeadingZeros
        value={inputAriaValue}
        onChange={onChange}
        onFocusChange={setInputFocused}
        className="py-3 px-4 focus-within:outline-focusOutline absolute top-0 left-0 bottom-0 right-12 bg-white z-1"
        minValue={minAriaDate}
        maxValue={maxAriaDate}
      >
        <Label
          className={clsx('text-gray-600', inputFocused || inputAriaValue ? 'hidden' : 'inline-block h-0 float-left')}
        >
          {placeholder}
        </Label>
        <div className={clsx('uppercase', inputFocused || inputAriaValue ? '' : 'opacity-0')}>
          <DateInput>{segment => <DateSegment segment={segment} />}</DateInput>
        </div>
        {inputAriaValue && (
          <button
            onClick={onDelete}
            className="w-6 h-6 text-xs rounded-full bg-gray-300 hover:bg-gray-400 inline-block absolute top-3 left-32"
          >
            X
          </button>
        )}
      </DateFieldAria>
    </I18nProvider>
  )
}

export default DateInputField

DateInputField.propTypes = {
  maximumDate: PropTypes.object,
  minimumDate: PropTypes.object,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  dateObject: PropTypes.object
}
