import React from 'react'
import PropTypes from 'prop-types'
import SubType from '../../config_items/SubType'
import CoverImage from '../../config_items/CoverImage'
import SwornDeclaration from '../../config_items/SwornDeclaration'
import FormName from '../../config_items/FormName'
import ConfigurationContainer from '../../ConfigurationContainer'
import { getVisibleConfigItems } from '../../../../../configs/formTypeConfig'
import PublishSchedule from '../../config_items/PublishSchedule'
import { fieldTitle } from '../../../../../configs/templatesConfig'
import HasWebhook from '../../config_items/HasWebhook'
import AutoResponse from '../../config_items/AutoResponse'
import FormHint from '../../config_items/FormHint'
import SubscribedEmail from '../../config_items/SubscribedEmail'
import Payment from '../../config_items/Payment'
import IsTrackable from '../../config_items/IsTrackable'
import UniqueResponsePerDocument from '../../config_items/UniqueResponsePerDocument'

const Configuration = ({ config }) => {
  const generalInformationItems = [
    {
      visible: true,
      component: <SubType config={config} />
    },
    {
      visible: true,
      component: (
        <FormName
          disabled={config.from_template}
          label="Título"
          hint={!config.from_template && 'Escribe el título que tendrá tu formulario.'}
        />
      )
    },
    {
      visible: true,
      component: <CoverImage />
    },
    {
      visible: true,
      component: (
        <FormHint
          label="Indicación para llenar el formulario"
          placeholder="Ej.: La recepción de documentos es de 8:00 a. m. a 6:00 p. m. Ingresa tu solicitud y un especialista se comunicará contigo."
          hint="Escribe la indicación  para que las personas completen el formulario. Incluye información importante, como fecha límite de registro de respuestas, horario de atención, qué pasos debe seguir, cuándo y cómo recibirá una respuesta, entre otros."
        />
      )
    }
  ]

  const configItems = [
    {
      visible: true,
      component: <h4 className="text-lg mb-6 font-bold">Experiencia de la ciudadanía</h4>,
      withoutSeparator: true
    },
    {
      visible: config.answers_by_email,
      component: <SubscribedEmail />
    },
    {
      visible: fieldTitle('auto_response', config.auto_response),
      component: <AutoResponse config={config} />
    },
    {
      visible: fieldTitle('is_trackable', config.is_trackable),
      component: <IsTrackable config={config} />
    },
    {
      visible: fieldTitle('unique_response_per_document', config.unique_response_per_document),
      component: <UniqueResponsePerDocument config={config} />
    },
    {
      visible: fieldTitle('sworn_declaration', config.sworn_declaration),
      component: <SwornDeclaration config={config} />
    },
    {
      visible: true,
      component: <h4 className="text-lg mb-6 font-bold">Configuración adicional</h4>,
      withoutSeparator: true
    },
    {
      visible: fieldTitle('it_costs', config.it_costs),
      component: <Payment config={config} />
    },
    {
      visible: true,
      component: <HasWebhook />
    },
    {
      visible: true,
      component: <PublishSchedule name="subscribed_email" />
    }
  ]

  const visibleGeneralInformationItems = getVisibleConfigItems(generalInformationItems)
  const visibleConfigItems = getVisibleConfigItems(configItems)

  return (
    <>
      <ConfigurationContainer configItems={visibleGeneralInformationItems} title="Información general" />
      <ConfigurationContainer configItems={visibleConfigItems} title="Configuración" />
    </>
  )
}

export default Configuration

Configuration.propTypes = {
  config: PropTypes.object
}
