import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { format } from 'date-fns'
import Error from '../Error'
import clsx from 'clsx'
import { marginSizes } from '../../../configs/sizesConfig'
import { useCustomField } from '../../../hooks/useCustomField'
import DatePicker from '@hassanmojab/react-modern-calendar-datepicker'
import { TextField, withStyles } from '@material-ui/core'
import CalendarIcon from '../../atoms/icons/CalendarIcon'
import { dateStringToObject } from '../../../utils/helpers'
import DateInputField from './DateInputField'

const myCustomLocale = {
  months: [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre'
  ],

  weekDays: [
    {
      name: 'Domingo',
      short: 'D',
      isWeekend: true
    },
    {
      name: 'Lunes',
      short: 'L'
    },
    {
      name: 'Martes',
      short: 'M'
    },
    {
      name: 'Miércoles',
      short: 'M'
    },
    {
      name: 'Jueves',
      short: 'J'
    },
    {
      name: 'Viernes',
      short: 'V'
    },
    {
      name: 'Sábado',
      short: 'S',
      isWeekend: true
    }
  ],
  weekStartingIndex: 0,
  getToday(gregorainTodayObject) {
    return gregorainTodayObject
  },
  toNativeDate(date) {
    return new Date(date.year, date.month - 1, date.day)
  },
  getMonthLength(date) {
    return new Date(date.year, date.month, 0).getDate()
  },
  transformDigit(digit) {
    return digit
  },
  nextMonth: 'Mes siguiente',
  previousMonth: 'Mes anterior',
  openMonthSelector: 'Abrir selector de mes',
  openYearSelector: 'Abrir selector de año',
  closeMonthSelector: 'Cerrar selector de mes',
  closeYearSelector: 'Cerrar selector de año',
  defaultPlaceholder: 'Selecciona...',
  from: 'desde',
  to: 'hasta',
  digitSeparator: ',',
  yearLetterSkip: 0,
  isRtl: false
}

const sizeMap = {
  '2xl': 'sm:max-w-sm',
  xl: 'sm:max-w-xs'
}

const StyledTextField = withStyles({
  root: {
    '& .MuiInputBase-input': {
      paddingTop: '13px',
      paddingBottom: '13px',
      fontSize: '16px'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none'
    }
  }
})(TextField)

const DateFieldText = ({ value, placeholder, ariaLabel }) => {
  return (
    <div className="flex flex-wrap items-center relative">
      <StyledTextField
        value={value || placeholder}
        className="w-full h-12 border-0 border-none text-sm pr-3"
        variant="outlined"
        name={name}
        aria-label={`${ariaLabel || placeholder}: ${value}`}
      />
      <button className="absolute right-2.5 top-2.5" type="button">
        <CalendarIcon />
      </button>
    </div>
  )
}

const DateCalendarField = ({
  name,
  placeholder,
  validate,
  margin = 'normal',
  size = 'xl',
  ariaLabel,
  hideError = false,
  minimumDate,
  maximumDate,
  onChange,
  className
}) => {
  const { input, gotError } = useCustomField(name, { validate })
  const objectDate = input.value && dateStringToObject(input.value)
  const [pickerValue, setPickerValue] = useState(objectDate)

  const handleChange = value => {
    const date = new Date(value.year, value.month - 1, value.day)
    const dateFormat = format(date, 'dd/MM/yyyy')
    setPickerValue(value)
    input.onChange(dateFormat)
    if (onChange) {
      onChange(dateFormat)
    }
  }

  const handleDelete = () => {
    setPickerValue(null)
    input.onChange('')
  }

  const DateField = ({ ref }) => (
    <div ref={ref}>
      <DateFieldText value={input.value} ariaLabel={ariaLabel} placeholder={placeholder} />
    </div>
  )

  DateField.propTypes = {
    ref: PropTypes.object
  }

  return (
    <div
      className={clsx(
        'bg-white relative',
        className,
        sizeMap[size],
        marginSizes[margin],
        'border-solid border-gray-800 border-2 rounded',
        gotError ? 'focus-within:border-red-500 border-red-500' : 'focus-within:border-yellow-500'
      )}
    >
      <DateInputField
        placeholder={placeholder}
        minimumDate={minimumDate}
        maximumDate={maximumDate}
        onChange={handleChange}
        onDelete={handleDelete}
        dateObject={objectDate}
      />
      <DatePicker
        value={pickerValue || null}
        onChange={handleChange}
        inputPlaceholder={placeholder}
        colorPrimary="#0056AC"
        locale={myCustomLocale}
        renderInput={DateField}
        calendarPopperPosition="bottom"
        minimumDate={minimumDate}
        maximumDate={maximumDate}
      />
      {!hideError && <Error name={name} className="absolute -bottom-6" />}
    </div>
  )
}

export default DateCalendarField

DateFieldText.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  ariaLabel: PropTypes.string
}

DateCalendarField.propTypes = {
  ariaLabel: PropTypes.string,
  hideError: PropTypes.bool,
  margin: PropTypes.string,
  maximumDate: PropTypes.object,
  minimumDate: PropTypes.object,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  validate: PropTypes.func,
  onChange: PropTypes.func,
  className: PropTypes.string
}
