import PropTypes from 'prop-types'
import React from 'react'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import { StepConnector, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(() => ({
  disabledIcon: {
    color: '#7D7F82!important'
  }
}))

export const HorizontalLinearStepper = ({ steps, linkSteps, activeStep, disabledSteps = [] }) => {
  const classes = useStyles()
  const styles = {
    connector: 'top-6 bg-neutral-200 h-1 left-lstep right-rstep z-10',
    line: 'border-0',
    icon: {
      active: 'text-blue-700',
      root: 'text-13.25 z-20 text-white',
      rootInactive: 'rounded-full h-14 w-14 border-neutral-200 border-2',
      text: 'text-2.8 font-bold',
      textActive: 'fill-white',
      textInactive: 'fill-gray'
    },
    root: 'py-0'
  }
  return (
    <div className="w-full">
      <Stepper
        activeStep={activeStep}
        classes={{ root: styles.root }}
        connector={
          <StepConnector
            classes={{
              root: styles.connector,
              line: styles.line
            }}
          />
        }
        alternativeLabel
      >
        {steps.map((label, index) => {
          const stepProps = {}
          return (
            <Step key={label} {...stepProps} disabled>
              <StepLabel
                StepIconProps={{
                  classes: {
                    root: clsx(
                      styles.icon.root,
                      activeStep < index && styles.icon.rootInactive,
                      disabledSteps.includes(index) && classes.disabledIcon
                    ),
                    text: clsx(
                      styles.icon.text,
                      activeStep >= index ? styles.icon.textActive : styles.icon.textInactive
                    ),
                    active: styles.icon.active
                  }
                }}
              >
                {linkSteps && linkSteps[index] ? (
                  <Link className="font-bold hidden md:block -mt-2" to={linkSteps[index]}>
                    {label}
                  </Link>
                ) : (
                  <div
                    className={clsx(
                      disabledSteps.includes(index) && 'text-gray-500',
                      'font-bold hidden md:block -mt-2'
                    )}
                  >
                    {label}
                  </div>
                )}
              </StepLabel>
            </Step>
          )
        })}
      </Stepper>
    </div>
  )
}

HorizontalLinearStepper.propTypes = {
  activeStep: PropTypes.number,
  steps: PropTypes.array,
  linkSteps: PropTypes.array,
  disabledSteps: PropTypes.array
}
