import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import InfoIcon from '@material-ui/icons/Info'
import { useCapLocks } from '../../hooks/feature/general/useCapLocks'
import { FormHelperText } from '@material-ui/core'
import { isTextUppercase } from '../../utils/helpers'

const CapsLockMessage = ({ evaluate, focused, value }) => {
  const isCapLocksActive = useCapLocks({ evaluate: true })
  const isValueOnUppercase = useMemo(() => isTextUppercase(value), [value])

  if (!evaluate) return null

  let message = null
  if (isCapLocksActive && focused) {
    message = 'La tecla Bloq Mayús está activada'
  } else if (isValueOnUppercase && !focused) {
    message = 'El texto está en mayúscula. Revisa si su escritura es correcta.'
  }

  return (
    message && (
      <FormHelperText
        focused
        className={`flex flex-row text-sm text-neutral-800 gap-2`}
        aria-live="polite"
        aria-atomic="true"
      >
        <InfoIcon className="w-5 h-5 rotate-180 transform" />
        {message}
      </FormHelperText>
    )
  )
}

export default CapsLockMessage

CapsLockMessage.propTypes = {
  evaluate: PropTypes.bool,
  focused: PropTypes.bool,
  value: PropTypes.string
}
