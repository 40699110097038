import React, { useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { PageNavigation } from '../../../organisms/navigation/PageNavigation'
import Container from '../../../atoms/Container'
import FormsHeader from '../../../molecules/FormsHeader'
import EnabledProcedureManagementsForm from '../../../organisms/enabled_procedure_managements/EnabledProcedureManagementsForm'
import { formSteps } from '../../../../configs/enabledProcedureManagementsConfig'
import useInstitution from '../../../../hooks/useInstitution'
import { getStepsFromTemplate } from '../../../../configs/formsConfig'
import { useMutation } from 'react-query'
import axios from 'axios'
import useProcedureManagement from '../../../../hooks/useProcedureManagement'
import { FormTypeContext } from '../../../../utils/contexts'

const createEnabledProcedureManagement = procedureManagementId => values =>
  axios.post(`/admin/v1/procedure_managements/${procedureManagementId}/enabled_procedure_managements.json`, values)

const EnabledProcedureManagementsNew = () => {
  const { procedure_management_id: procedureManagementId } = useParams()
  const history = useHistory()
  const { mutate } = useMutation(createEnabledProcedureManagement(procedureManagementId), {
    onSuccess: ({ data }) => history.push(`/gestiones-de-tramites-habilitadas/${data.id}/areas-y-usuarios`)
  })
  const { getInstitution } = useInstitution()
  const { data: institution, status: institutionStatus } = getInstitution()
  const { data: procedureManagement, status: procedureManagementStatus } = useProcedureManagement(procedureManagementId)
  const initialValues = useMemo(() => {
    if (procedureManagementStatus === 'success')
      return {
        ...procedureManagement.form,
        id: null,
        privacy_policy: undefined,
        steps_attributes: getStepsFromTemplate(procedureManagement.form.steps_attributes)
      }
  }, [procedureManagementStatus])
  if (procedureManagementStatus !== 'success' || institutionStatus !== 'success') return null
  const config = {
    enabled_payment_methods: institution.enabled_payment_methods,
    tasks_with_payment: procedureManagement.tasks_with_payment,
    example_privacy_policy: procedureManagement.form.privacy_policy,
    add_questions: procedureManagement.add_questions,
    sort_questions: procedureManagement.sort_questions,
    edit_delete_questions: procedureManagement.edit_delete_questions
  }
  const linkSteps = [`/gestiones-de-tramites-habilitadas/crear`]
  return (
    <>
      <PageNavigation />
      <Container className="py-12">
        <FormTypeContext.Provider value={'enabled_procedure_management'}>
          <FormsHeader
            title="Habilitar una gestión de trámite"
            hint="Configura la gestión de trámite que deseas habilitar para tu institución."
            steps={formSteps}
            activeStep={formSteps.FORM}
            linkSteps={linkSteps}
            size="l"
          />
          <EnabledProcedureManagementsForm
            initialValues={initialValues}
            config={config}
            onSave={mutate}
            onGoBack={() => history.push(`/gestiones-de-tramites-habilitadas/crear`)}
          />
        </FormTypeContext.Provider>
      </Container>
    </>
  )
}
export default EnabledProcedureManagementsNew
