import React from 'react'
import { useParams } from 'react-router-dom'
import QueryPageForm from '../../../organisms/quick_searches/QueryPageForm'
import useQuickSearch from '../../../../hooks/useQuickSearch'
import { getEditLinkSteps } from '../../../../configs/quickSearchesConfig'

const QuickSearchEdit = () => {
  const { id: quickSearchId } = useParams()
  const { getQuickSearch, updateQuickSearch } = useQuickSearch()
  const { data: quickSearchData, status } = getQuickSearch(quickSearchId)

  const linkSteps = getEditLinkSteps(quickSearchId, 0)

  if (status !== 'success') return null
  return (
    <QueryPageForm linkSteps={linkSteps} initialValues={quickSearchData} mutate={updateQuickSearch} action="edit" />
  )
}
export default QuickSearchEdit
