import PropTypes from 'prop-types'
import React from 'react'
import TextField from '../../molecules/fields/TextField'
import * as validations from '../../../utils/validations'

const FileField = ({ baseName, question }) => {
  return (
    <div className="grid gap-4">
      <TextField
        label="Etiqueta de la pregunta"
        size="full"
        name={`${baseName}.payload.label`}
        validate={validations.required()}
        margin="none"
        errorClassName="relative inset-0"
        hint={question.payload.label}
        placeholder="Ingresa la traducción."
        capsLockInfo
      />
    </div>
  )
}

export default FileField

FileField.propTypes = {
  baseName: PropTypes.string,
  question: PropTypes.object
}
