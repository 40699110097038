import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { PageNavigation } from '../../../organisms/navigation/PageNavigation'
import Container from '../../../atoms/Container'
import FormsHeader from '../../../molecules/FormsHeader'
import EnabledProcedureManagementsForm from '../../../organisms/enabled_procedure_managements/EnabledProcedureManagementsForm'
import { formSteps, getEditLinkSteps } from '../../../../configs/enabledProcedureManagementsConfig'
import { useQuery, useQueryClient } from 'react-query'
import axios from 'axios'
import { FormTypeContext } from '../../../../utils/contexts'

const fetchEnabledProcedureManagement = id =>
  axios.get(`/admin/v1/enabled_procedure_managements/${id}.json`).then(a => a.data)

const updateEnabledProcedureManagement = (id, values) =>
  axios.put(`/admin/v1/enabled_procedure_managements/${id}.json`, values)

const EnabledProcedureManagementsEdit = () => {
  const history = useHistory()
  const { id } = useParams()
  const { data: enabledProcedureManagement, status } = useQuery(
    ['enabled_procedure_management', id],
    () => fetchEnabledProcedureManagement(id),
    { refetchOnWindowFocus: false }
  )
  const queryClient = useQueryClient()
  const handleFormUpdate = async values => {
    try {
      await updateEnabledProcedureManagement(id, values)
      await queryClient.invalidateQueries(['enabled_procedure_management', id])
      history.push(`/gestiones-de-tramites-habilitadas/${id}/areas-y-usuarios`)
    } catch (error) {
      alert('Ocurrió un error al intentar guardar.')
    }
  }

  if (status !== 'success') return null
  const initialValues = {
    ...enabledProcedureManagement.form,
    procedure_management_id: enabledProcedureManagement.procedure_management_id,
    destiny_branch_office_type: enabledProcedureManagement.destiny_branch_office_type,
    kind_payment: enabledProcedureManagement.kind_payment
  }
  const config = {
    ...enabledProcedureManagement.form_config,
    add_questions: enabledProcedureManagement.procedure_management_config.add_questions,
    sort_questions: enabledProcedureManagement.procedure_management_config.sort_questions,
    edit_delete_questions: enabledProcedureManagement.procedure_management_config.edit_delete_questions
  }
  const linkSteps = getEditLinkSteps(id, 1)

  return (
    <>
      <PageNavigation />
      <Container className="py-12">
        <FormTypeContext.Provider value={'enabled_procedure_management'}>
          <FormsHeader
            title="Habilitar una gestión de trámite"
            hint="Configura la gestión de trámite que deseas habilitar para tu institución."
            steps={formSteps}
            activeStep={formSteps.FORM}
            size="l"
            linkSteps={linkSteps}
            disabledSteps={[0]}
          />
          <EnabledProcedureManagementsForm
            initialValues={initialValues}
            config={config}
            onSave={handleFormUpdate}
            onGoBack={() => history.push(`/gestiones-de-tramites-habilitadas`)}
          />
        </FormTypeContext.Provider>
      </Container>
    </>
  )
}
export default EnabledProcedureManagementsEdit
