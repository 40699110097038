import PropTypes from 'prop-types'
import React from 'react'
import Link from '@material-ui/core/Link'
import { Link as RouterLink, useHistory } from 'react-router-dom'
import useExport from '../../../hooks/useExport'
import clsx from 'clsx'
import { isSuccessful } from '../../../hooks/useQuery'
import SelectButton from '../../molecules/SelectButton'

const ExportSelectButton = ({ url, filters, className, mainExportable, listExportables }) => {
  const history = useHistory()
  const { create, getStatus } = useExport()
  const { data, status } = getStatus(
    ['export_status', mainExportable.exportable.type, mainExportable.exportable.id],
    { exportable_id: mainExportable.exportable.id, exportable_type: mainExportable.exportable.type },
    { refetchOnWindowFocus: true }
  )
  const handleExport = (e, exportable) => {
    e.preventDefault()
    create(filters, exportable, () => history.push(url))
  }

  const filterExport = { ...filters }
  delete filterExport.page
  delete filterExport.sort

  const hasEnabledExport =
    isSuccessful(status) && (data.enable || (data.enable_with_filter && Object.keys(filterExport).length > 0))

  return (
    <div className={clsx('flex md:items-center flex-col w-full md:w-max', className)}>
      <SelectButton
        id="exportMenu"
        optionButtonName="Mas opciones de exportación"
        mainOption={{
          name: mainExportable.title,
          onClick: e => handleExport(e, mainExportable.exportable),
          disabled: !hasEnabledExport
        }}
        options={listExportables.map(el => ({
          name: el.title,
          shortName: el.shortTitle,
          onClick: e => handleExport(e, el.exportable)
        }))}
      />
      <Link component={RouterLink} to={url} className="font-medium">
        Historial de exportaciones
      </Link>
    </div>
  )
}

export default ExportSelectButton

ExportSelectButton.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.object,
  url: PropTypes.string,
  mainExportable: PropTypes.object,
  listExportables: PropTypes.array
}
