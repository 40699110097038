import PropTypes from 'prop-types'
import React, { useState } from 'react'
import SelectField from '../../molecules/fields/SelectField'
import * as validations from '../../../utils/validations'
import AutocompleteField from '../../molecules/fields/AutocompleteField'
import useTextValidation from '../../../hooks/useTextValidation'
import { getLabelWithManualRequest } from '../../../configs/formsConfig'
import OtherField from '../../molecules/fields/OtherField'
import clsx from 'clsx'
import { marginSizes } from '../../../configs/sizesConfig'
import { otherOption } from '../../../configs/questionTypesAdminConfig'
import { useField } from 'react-final-form'

const DropdownCitizenField = ({
  name,
  index,
  payload,
  required,
  manualRequest = false,
  disabled = false,
  margin,
  onChange
}) => {
  const [openOther, setOpenOther] = useState(false)
  const baseLabel = index ? `${index}. ${payload?.label}` : payload?.label
  const label = getLabelWithManualRequest(baseLabel, manualRequest)
  const options = [...(payload?.alternatives || []).map(o => ({ value: o.label, label: o.label, type: o.type }))]
  const emptyLabel = payload.has_indication_text ? payload.indication_text : undefined
  const translationText = useTextValidation()
  const hasOtherOption = options.at(-1).type === otherOption.type
  const { input: dropDownField } = useField(`${name}`)
  const { input: otherField } = useField(`${name}.other_text`)

  const handleChange = value => {
    setOpenOther(hasOtherOption && value === otherOption.label)
    if (value) {
      onChange && onChange(value)
      otherField.onChange(null)
    } else {
      dropDownField.onChange({})
    }
  }

  return (
    <div className={clsx('flex flex-col gap-2', marginSizes[margin])}>
      {options.length < 10 ? (
        <SelectField
          name={`${name}.selected`}
          label={label}
          className="block"
          hint={payload?.hint}
          options={options}
          disabled={disabled}
          validate={required && validations.required(translationText.required)}
          margin={'none'}
          onChange={handleChange}
          size="2xl"
          emptyLabel={emptyLabel}
        />
      ) : (
        <AutocompleteField
          name={`${name}.selected`}
          label={label}
          hint={payload?.hint}
          options={options}
          margin={'none'}
          size="2xl"
          onChange={handleChange}
          validate={required && validations.required(translationText.required)}
          disabled={disabled}
          emptyLabel={emptyLabel}
        />
      )}
      <OtherField name={name} open={openOther} validate={required && validations.required(translationText.required)} />
    </div>
  )
}

export default DropdownCitizenField

DropdownCitizenField.propTypes = {
  disabled: PropTypes.bool,
  index: PropTypes.number,
  margin: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  payload: PropTypes.object,
  required: PropTypes.bool,
  manualRequest: PropTypes.bool
}
