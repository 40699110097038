import { getLinkSteps } from '../utils/helpersAdmin'

const privacyPolicyExampleTypeOptions = [
  { value: 'standard', label: 'El ejemplo estándar' },
  { value: 'modified', label: 'Un ejemplo modificado' }
]

const switches = [
  {
    name: 'legal_conditions',
    label: '¿El personal de la entidad deberá aceptar condiciones obligatorias para usar la plantilla?'
  },
  { name: 'answers_by_email', label: '¿El personal de la entidad podrá recibir las respuestas al correo?' },
  { name: 'sort_questions', label: '¿El personal de la entidad podrá cambiar el orden de las preguntas?' },
  { name: 'add_questions', label: '¿El personal de la entidad podrá añadir preguntas y páginas?' },
  { name: 'edit_delete_questions', label: '¿El personal de la entidad podrá editar y borrar preguntas?' }
]

const options = [
  { value: 'yes', label: 'Sí' },
  { value: 'no', label: 'No' },
  { value: 'official_decide', label: 'Dejar que el funcionario decida' }
]

const selects = {
  it_costs: {
    label: '¿El trámite/servicio requiere un pago de la ciudadanía?',
    fieldTitle: {
      yes: {
        title: 'Pago del trámite',
        hint: 'Este trámite tiene un costo, indica el monto y medio de pago.'
      },
      official_decide: {
        title: 'Agregar medio de pago',
        hint: 'El formulario incluirá automáticamente una sección para solicitar el pago a la ciudadanía.'
      },
      no: null
    }
  },
  auto_response: {
    label: '¿El trámite/servicio requiere una respuesta automática para la ciudadanía?',
    fieldTitle: {
      yes: {
        title: 'Establecer respuesta automática',
        hint: 'Se enviará una respuesta automática por correo electrónico a todos los que completen el formulario.'
      },
      official_decide: {
        title: 'Establecer respuesta automática',
        hint: 'Escribe el mensaje que se enviará a las personas que completen el formulario.'
      },
      no: null
    }
  },
  is_trackable: {
    label: '¿El trámite/servicio requiere que la ciudadanía pueda hacer seguimiento?',
    fieldTitle: {
      yes: {
        title: 'Seguimiento de trámite habilitado',
        hint: 'Las personas podrán ver el formulario que enviaron, así como el estado de atención: en revisión, rechazado o atendido.'
      },
      official_decide: {
        title: 'Habilitar seguimiento de trámite',
        hint: 'Las personas podrán ver el formulario que enviaron, así como el estado de atención: en revisión, rechazado o atendido.'
      },
      no: null
    }
  },
  unique_response_per_document: {
    label: '¿El trámite/servicio requiere limitar a una respuesta por documento de identidad?',
    fieldTitle: {
      yes: {
        title: 'Limitar a una respuesta por documento de identidad',
        hint: 'El formulario permitirá una sola respuesta por documento de identidad.'
      },
      official_decide: {
        title: 'Limitar a una respuesta por documento de identidad',
        hint: 'El formulario permitirá una sola respuesta por documento de identidad.'
      },
      no: null
    }
  },
  sworn_declaration: {
    label: '¿La información que ingresa la ciudadanía debe tener carácter de declaración jurada?',
    fieldTitle: {
      yes: {
        title: 'Este formulario tiene carácter de declaración jurada',
        hint: 'Para enviar su respuesta al formulario, las personas deberán aceptar la declaración jurada de veracidad de la información que han ingresado.'
      },
      official_decide: {
        title: 'Dar carácter de declaración jurada al formulario',
        hint: 'Para enviar su respuesta al formulario, las personas deberán aceptar la declaración jurada de veracidad de la información que han ingresado.'
      },
      no: null
    }
  }
}

const selectsOptions = Object.keys(selects).map(code => ({ name: code, label: selects[code].label, options }))

const fieldTitle = (code, option) => selects[code].fieldTitle[option]

const FormSteps = {
  CREATION: {
    label: 'Configuración de la plantilla',
    hint: 'Agrega la información general de la plantilla y configúrala.'
  },
  QUESTION: {
    label: 'Preguntas de la plantilla',
    hint: 'Agrega las preguntas de la plantilla.'
  },
  PREVIEW: {
    label: 'Vista previa y publicación',
    hint: 'Revisa tu plantilla y publícala para que pueda ser utilizada por los funcionarios públicos.'
  }
}

const formValuesPreview = template => {
  const formValues = { ...template }
  formValues.max_steps = formValues.steps_attributes.length
  formValues.it_costs = template.it_costs === 'yes'
  formValues.sworn_declaration = template.sworn_declaration === 'yes'
  formValues.privacy_policy = { url: null }
  formValues.cover_image = template.cover_image
  if (formValues.it_costs) {
    formValues.cost = '0'
    formValues.kind_payment = ['bank_transfer']
    formValues.institution = {
      name: 'Nombre de la institución',
      payment_transfer_accounts: [
        { company_name: 'Entidad', ruc: 'RUC', bank: 'bcp', number: 'XXXXXXXXXXXXXXX', cci_number: 'XXXXXXXXXXXXXXX' }
      ]
    }
  }
  return formValues
}

const getEditLinkSteps = (id, indexToIgnore) =>
  getLinkSteps(indexToIgnore, [
    `/plantillas/${id}/editar`,
    `/plantillas/${id}/editar-preguntas`,
    `/plantillas/${id}/vista-previa`
  ])

export {
  privacyPolicyExampleTypeOptions,
  switches,
  selectsOptions,
  fieldTitle,
  FormSteps,
  formValuesPreview,
  getEditLinkSteps
}
