import PropTypes from 'prop-types'
import React, { useRef, useState, useEffect } from 'react'
import { useField } from 'react-final-form'
import ReactCrop from 'react-image-crop'

const getRealSize = (crop, realWidth, realHeight) => {
  if (!crop) return { width: 0, height: 0, x: 0, y: 0 }

  const { width, height, x, y } = crop
  return {
    width: Math.round((width * realWidth) / 100),
    height: Math.round((height * realHeight) / 100),
    x: Math.round((x * realWidth) / 100),
    y: Math.round((y * realHeight) / 100)
  }
}

const getCropSize = (value, realWidth, realHeight) => {
  if (!value) return { width: 0, height: 0, x: 0, y: 0 }

  const { width, height, x, y } = value
  return {
    unit: '%',
    width: (width * 100) / realWidth,
    height: (height * 100) / realHeight,
    x: Math.round((x * 100) / realWidth),
    y: Math.round((y * 100) / realHeight)
  }
}

const ImageCrop = ({ image, minHeight, minWidth, maxWidth, maxHeight, onLoadValidImage }) => {
  const { input } = useField('cover_image_crop')
  const [imgSrc, setImgSrc] = useState('')
  const imgRef = useRef(null)
  const [crop, setCrop] = useState()
  const [realImageWidth, setRealImageWidth] = useState()
  const [realImageHeight, setRealImageHeight] = useState()
  const realMaxWidth = (imgRef?.current?.offsetWidth / realImageWidth) * maxWidth
  const realMaxHeight = (imgRef?.current?.offsetHeight / realImageHeight) * maxHeight
  const realMinWidth = (imgRef?.current?.offsetWidth / realImageWidth) * minWidth
  const realMinHeight = (imgRef?.current?.offsetHeight / realImageHeight) * minHeight

  useEffect(() => {
    setCrop(undefined)
    if (!image.saved) {
      const reader = new FileReader()
      const fileImage = new Image()
      reader.addEventListener('load', file => {
        setImgSrc(reader.result?.toString() || '')

        fileImage.src = file.target.result
        fileImage.onload = function () {
          if (fileImage.width < minWidth || fileImage.height < minHeight) {
            onLoadValidImage && onLoadValidImage(false)
          } else {
            setRealImageWidth(fileImage.width)
            setRealImageHeight(fileImage.height)
            onLoadValidImage && onLoadValidImage(true)
          }
        }
      })
      reader.readAsDataURL(image)
    } else {
      setImgSrc(image.url)
      const fileImage = new Image()
      fileImage.src = image.url
      fileImage.onload = function () {
        setRealImageWidth(fileImage.width)
        setRealImageHeight(fileImage.height)
        onLoadValidImage(true)
      }
    }
  }, [image])

  const onImageLoad = e => {
    if (!image.saved) {
      const { width, height } = e.currentTarget
      const cropWidth = width > realMaxWidth ? realMaxWidth : width
      const cropHeight = height > realMaxHeight ? realMaxHeight : height
      const maxWidth = (cropWidth * 100) / width
      const maxHeight = (cropHeight * 100) / height
      handleCrop({ unit: '%', x: 0, y: 0, width: maxWidth, height: maxHeight })
    } else {
      const cropSize = getCropSize(input?.value, realImageWidth, realImageHeight)
      setCrop(cropSize)
    }
  }

  const handleCrop = percentCrop => {
    const { x, y, width, height } = getRealSize(percentCrop, realImageWidth, realImageHeight)
    input.onChange({ x, y, width, height })
    setCrop(percentCrop)
  }

  return (
    <>
      <div ref={imgRef} className="text-0">
        {realImageWidth && (
          <ReactCrop
            crop={crop}
            onChange={(_, percentCrop) => handleCrop(percentCrop)}
            minWidth={realMinWidth}
            minHeight={realMinHeight}
            maxHeight={realMaxHeight}
            maxWidth={realMaxWidth}
          >
            <img alt="" src={imgSrc} onLoad={onImageLoad} />
          </ReactCrop>
        )}
      </div>
      {realImageWidth && (
        <div className="rounded-md p-4 mt-4 flex items-center pr-4 border-gray-300 border">
          <p className="flex gap-4">
            <span className="w-20 font-bold">Medidas:</span>
            <span className="font-medium">Ancho: {input.value.width}</span>
            <span className="font-medium">Alto: {input.value.height}</span>
          </p>
        </div>
      )}
    </>
  )
}

export default ImageCrop

ImageCrop.propTypes = {
  image: PropTypes.object,
  minWidth: PropTypes.number,
  minHeight: PropTypes.number,
  maxWidth: PropTypes.number,
  maxHeight: PropTypes.number,
  onLoadValidImage: PropTypes.func
}
