import PropTypes from 'prop-types'
import React from 'react'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { focusOnFirstError } from '../../../utils/decorators'
import { Button } from '../../atoms/Button'
import { markForDestroyMutator } from '../../../utils/formMutators'
import UserTypesSection from './UserTypesSection'
import { Link } from 'react-router-dom'

const EnabledProcedureManagementsAreasAndUsersForm = ({ enabledProcedureManagementId, initialValues, onSave }) => {
  const convertToSubmit = async values => {
    let redirectToUserCreate = false
    let redirectToUserCreateParams = {}
    const formData = { accesses_attributes: [] }
    for (const userType of values.user_types_attributes) {
      for (const area of userType.areas_attributes) {
        for (const access of area.accesses_attributes) {
          if (access?.user_id) {
            formData.accesses_attributes.push({
              ...access,
              user_type_id: userType.id,
              area_id: area.id,
              branch_office_id: values.branch_office_id,
              institution_id: values.institution_id,
              _destroy: area._destroy === '1' || access._destroy === '1' ? '1' : undefined
            })
          }
          if (access?.to_user_page) {
            redirectToUserCreate = true
            redirectToUserCreateParams = {
              area_id: area.id,
              branch_office_id: values.branch_office_id
            }
          }
        }
      }
    }
    await onSubmit(formData, redirectToUserCreate, redirectToUserCreateParams)
  }

  const onSubmit = async (values, redirectToUserCreate, redirectToUserCreateParams) => {
    try {
      await onSave(values, redirectToUserCreate, redirectToUserCreateParams)
    } catch (error) {
      alert('Ocurrió un error al intentar guardar.')
    }
  }
  return (
    <>
      <Form
        onSubmit={convertToSubmit}
        mutators={{ ...arrayMutators, markForDestroy: markForDestroyMutator }}
        decorators={[focusOnFirstError]}
        initialValues={initialValues}
        subscription={{ submitting: true }}
      >
        {({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <UserTypesSection />
            <div className="flex flex-col-reverse md:flex-row justify-end py-4 px-4 md:px-0">
              <Button
                type="button"
                el={Link}
                to={`/gestiones-de-tramites-habilitadas/${enabledProcedureManagementId}/editar`}
                variant="secondary"
                className="md:max-w-56 lg:max-w-72 md:mr-3"
                size="full"
              >
                Regresar
              </Button>
              <Button type="submit" className="md:max-w-56 lg:max-w-72 mb-3 md:mb-0" size="full" disabled={submitting}>
                Guardar y continuar
              </Button>
            </div>
          </form>
        )}
      </Form>
    </>
  )
}
export default EnabledProcedureManagementsAreasAndUsersForm

EnabledProcedureManagementsAreasAndUsersForm.propTypes = {
  enabledProcedureManagementId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  initialValues: PropTypes.object,
  onSave: PropTypes.func
}
