import React from 'react'
import { PageNavigation } from '../../../organisms/navigation/PageNavigation'
import Container from '../../../atoms/Container'
import { useHistory, useParams } from 'react-router-dom'
import axios from 'axios'
import FormsHeader from '../../../molecules/FormsHeader'
import useTemplate from '../../../../hooks/useTemplate'
import { useMutation, useQueryClient } from 'react-query'
import Questions from '../../../organisms/templates/Questions'
import { FormSteps, getEditLinkSteps } from '../../../../configs/templatesConfig'
import * as uuid from 'uuid'

const updateForm =
  id =>
  ({ values, config }) =>
    axios.put(`/admin/v1/templates/${id}.json`, values, config).then(res => res.data)

const TemplateNewQuestions = () => {
  const history = useHistory()
  const queryClient = useQueryClient()
  const { id } = useParams()
  const { data: template, status } = useTemplate(id)
  const { mutate } = useMutation(updateForm(id), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['template', id])
      history.push(`/plantillas/${id}/vista-previa?action=new`)
    }
  })

  const templateValues =
    template && template.steps_attributes.length > 0
      ? template
      : { ...template, steps_attributes: [{ questions_attributes: [{ key: uuid.v4() }] }] }

  const linkSteps = getEditLinkSteps(id, 1)
  if (status !== 'success') return null
  return (
    <>
      <PageNavigation breadcrumbData={template} />
      <Container className="pt-12">
        <FormsHeader
          title="Crear una plantilla de formulario"
          hint="Crea una plantilla para las instituciones públicas que usan Facilita."
          steps={FormSteps}
          activeStep={FormSteps.QUESTION}
          linkSteps={linkSteps}
        />
        <Questions template={templateValues} mutateForm={mutate} />
      </Container>
    </>
  )
}

export default TemplateNewQuestions
