import PropTypes from 'prop-types'
import React from 'react'
import TextField from '../../molecules/fields/TextField'
import * as validations from '../../../utils/validations'

const DateField = ({ baseName, question, questionTranslated }) => {
  return (
    <div className="grid flex-wrap gap-4">
      <span>
        <TextField
          label="Etiqueta de la pregunta"
          margin="none"
          size="full"
          name={`${baseName}.payload.label`}
          validate={validations.required()}
          errorClassName="relative inset-0"
          hint={question.payload.label}
          placeholder="Ingresa la traducción."
          capsLockInfo
        />
      </span>
    </div>
  )
}

export default DateField

DateField.propTypes = {
  baseName: PropTypes.string,
  question: PropTypes.object,
  questionTranslated: PropTypes.object
}
