import PropTypes from 'prop-types'
import React from 'react'
import RadioField from '../../molecules/fields/RadioField'
import TextField from '../../molecules/fields/TextField'
import Value from '../../molecules/Value'
import WhenFieldChanges from '../../molecules/WhenFieldChanges'
import * as validations from '../../../utils/validations'
import useTextValidation from '../../../hooks/useTextValidation'
import { useTranslation } from 'react-i18next'

const options = [
  {
    value: 'email',
    label: 'email_phone_label_email',
    citizenLabel: 'email_phone_label_citizen_email'
  },
  {
    value: 'cellphone',
    label: 'email_phone_label_cellphone',
    citizenLabel: 'email_phone_label_citizen_cellphone'
  }
]

export const EmailPhoneCitizenField = ({ index, name, payload, required, disabled = false }) => {
  const label = index ? `${index}. ${payload?.label}` : payload?.label
  const translationText = useTextValidation()
  const { t } = useTranslation('form_field_options')
  return (
    <div>
      <RadioField
        hint={payload?.hint}
        label={label}
        name={`${name}.contact_medium`}
        options={options}
        validate={required && validations.required(translationText.required)}
        disabled={disabled}
        nsTranslation="form_field_options"
      />
      <WhenFieldChanges field={`${name}.contact_medium`} set={`${name}.value`} to={null} />
      <Value name={`${name}.contact_medium`}>
        {({ value: contactMedium }) => {
          if (!contactMedium) return null
          return (
            <TextField
              label={t(options.find(o => o.value === contactMedium).citizenLabel)}
              name={`${name}.value`}
              size="2xl"
              validate={validations.mix(
                required && validations.required(translationText.required),
                contactMedium === 'email' && validations.isEmail(translationText.isEmail),
                contactMedium === 'email' && validations.notHasBlankSpaces(translationText.notHasBlankSpaces),
                contactMedium === 'cellphone' && validations.isPeruvianCellphone(translationText.isPhone)
              )}
              disabled={disabled}
              capsLockInfo={contactMedium === 'email'}
            />
          )
        }}
      </Value>
    </div>
  )
}

EmailPhoneCitizenField.propTypes = {
  disabled: PropTypes.bool,
  index: PropTypes.number,
  name: PropTypes.string,
  payload: PropTypes.object,
  required: PropTypes.bool
}
