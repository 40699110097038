import { EditorState, ContentState, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'

export const parseHtml2EditorState = html => {
  const blockFromHtml = htmlToDraft(html)
  const { contentBlocks, entityMap } = blockFromHtml
  const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap)
  return EditorState.createWithContent(contentState)
}

export const parseEditorState2Html = state => {
  const content = state.getCurrentContent()
  const raw = convertToRaw(content)
  return draftToHtml(raw)
}

export const getObjectFromUrlValues = (requiredValues, urlSearchParams) => {
  return Object.fromEntries(requiredValues.map(param => [param, urlSearchParams.get(param)]))
}

export const getLinkSteps = (indexToIgnore, urlList) =>
  urlList.map((label, index) => (index === indexToIgnore ? undefined : label))
