import PropTypes from 'prop-types'
import React from 'react'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { useField } from 'react-final-form'
import clsx from 'clsx'
import Error from '../Error'
import { required } from '../../../utils/validations'

const IconSelectorField = ({ options = [], name, ...props }) => {
  const { input } = useField(name, { validate: required(), ...props })
  return (
    <div className="items-center mb-8">
      <RadioGroup name={name} className="flex flex-row gap-4" {...input}>
        {options.map((option, index) => (
          <FormControlLabel
            key={index}
            value={option.value}
            control={<Radio className="opacity-0 absolute p-4 z-0" />}
            classes={{ root: 'ml-0 mr-0' }}
            aria-label={option.text}
            label={
              <div
                className={clsx(
                  'border-2 border-gray-300 rounded-lg relative p-3 w-30 h-36 flex flex-col gap-2 items-center justify-center z-1',
                  input.value === option.value ? 'bg-purple-650' : 'bg-white hover:bg-gray-200'
                )}
              >
                <option.IconSvg
                  className={clsx(input.value === option.value ? 'fill-white' : 'fill-gray-550 hover:fill-gray-650')}
                  ariaLabel=""
                />
                <span className={clsx('text-sm text-center', input.value === option.value && 'text-white')}>
                  {option.text}
                </span>
              </div>
            }
          />
        ))}
      </RadioGroup>
      <Error name={name} />
    </div>
  )
}

export default IconSelectorField

IconSelectorField.propTypes = {
  name: PropTypes.string,
  options: PropTypes.array
}
