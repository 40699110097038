import React, { useEffect } from 'react'
import FieldsSeparator from '../../atoms/FieldsSeparator'
import RadioField from '../../molecules/fields/RadioField'
import * as validations from '../../../utils/validations'
import Alert from '../../molecules/Alert'
import { Button } from '../../atoms/Button'
import { Link } from 'react-router-dom'
import { useAuth } from '../../molecules/Auth'
import { useForm } from 'react-final-form'

const DestinyBranchOfficeType = () => {
  const { user } = useAuth()
  const { change } = useForm()
  useEffect(() => {
    if (!user.institution_primary_branch) {
      change('destiny_branch_office_type', 'all')
    }
  }, [])
  return (
    <>
      <FieldsSeparator />
      <RadioField
        label="Habilitación del trámite para otras sedes"
        name="destiny_branch_office_type"
        disabled={!user.institution_primary_branch}
        options={[
          {
            value: 'primary_branch',
            label: (
              <div className="font-normal">
                <span className="font-bold">Solo sede principal</span>: el trámite solo será habilitado para la sede
                central.
              </div>
            )
          },
          {
            value: 'all',
            label: (
              <div className="font-normal">
                <span className="font-bold">Otras sedes:</span> el trámite podrá ser habilitado por los coordinadores de
                sedes para su sede.
              </div>
            )
          }
        ]}
        validate={validations.required()}
      />
      {!user.institution_primary_branch && (
        <Alert className="items-center">
          <span>Debes asignar una sede como principal para que las solicitudes puedan ser enviadas a esta.</span>
          <Button variant="text" el={Link} to="/sedes" fullWidth={false}>
            Ir a sedes
          </Button>
        </Alert>
      )}
    </>
  )
}
export default DestinyBranchOfficeType
