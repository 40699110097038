import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { Editor } from 'react-draft-wysiwyg'
import { useField } from 'react-final-form'
import Error from '../Error'
import { LinkToolBar } from './wysiwyg/LinkToolBar'
import { Modifier, EditorState, SelectionState } from 'draft-js'
import { parseEditorState2Html, parseHtml2EditorState } from '../../../utils/helpersAdmin'
import clsx from 'clsx'
import { marginSizes } from '../../../configs/sizesConfig'
import VideoToolBar from './wysiwyg/VideoToolBar'
import customBlockRender from './wysiwyg/CustomBlockRender'
import ImageToolBar from './wysiwyg/ImageToolBar'
import useFieldValue from '../../../hooks/useFieldValue'
import CapsLockMessage from '../CapsLockMessage'
import { useCustomField } from '../../../hooks/useCustomField'

const getContentLength = editorState => editorState.getCurrentContent().getPlainText().length
const forbidNewWords = (words, state, maxContentLength) =>
  maxContentLength > 0 ? getContentLength(state) + words.length > maxContentLength : false

const setSelectionAtTheBeginning = (startKey, focusOffSet) =>
  new SelectionState({
    anchorKey: startKey,
    anchorOffset: 0,
    focusKey: startKey,
    focusOffset: focusOffSet
  })

const getSelectionAttr = selection => ({
  endKey: selection.getEndKey(),
  startOffset: selection.getStartOffset(),
  endOffset: selection.getEndOffset(),
  startKey: selection.getStartKey()
})

const getEditorAttr = state => ({
  selection: state.getSelection(),
  content: state.getCurrentContent(),
  anchorKey: state.getSelection().getAnchorKey(),
  currentContentBlock: state.getCurrentContent().getBlockForKey(state.getSelection().getAnchorKey())
})

const deletionAllowedTypes = ['unordered-list-item', 'ordered-list-item']
const nonCharactersBlocksTypes = ['unordered-list-item', 'ordered-list-item']
const isEmptySelection = (startOff, endOff, startKey, endKey) => startOff === endOff && startKey === endKey

const setPlaceholder = (state, placeholder) => {
  const { content } = getEditorAttr(state)
  if (content.getBlockMap().size === 1 && nonCharactersBlocksTypes.includes(content.getFirstBlock().getType())) {
    return ''
  }
  return placeholder
}

export const HtmlField = ({
  name,
  label,
  hint,
  maxContentLength = 500,
  placeholder = '',
  initialValue = '<p></p>',
  validate,
  margin = 'normal',
  canUploadFiles,
  fullName = '',
  isBasic = false,
  hasMaxContentLength = true,
  ariaLabel,
  editorClassName,
  editorWrapperClassName,
  className,
  allowScrolling = false,
  capsLockInfo,
  ...props
}) => {
  const options = isBasic ? ['inline'] : ['inline', 'list', 'link']
  const refEditor = useRef()
  const { input } = useField(name, { validate, initialValue })
  const { gotError } = useCustomField(name)
  const [editorState, setEditorState] = useState(() => parseHtml2EditorState(input.value))
  const [focused, setFocused] = useState(false)
  const key = useFieldValue(`${fullName}.key`)
  const htmlTextValue = editorState.getCurrentContent().getPlainText()

  useEffect(() => {
    fullName && setEditorState(() => parseHtml2EditorState(input.value))
  }, [key])

  const handleBeforeInput = char => hasMaxContentLength && forbidNewWords(char, editorState, maxContentLength)
  const handlePastedText = text => hasMaxContentLength && forbidNewWords(text, editorState, maxContentLength)
  const handleReturn = () => maxContentLength !== 0 && getContentLength(editorState) > maxContentLength - 1

  const handleEditorStateChange = value => {
    let parsed = parseEditorState2Html(value).trim()
    parsed = parsed.replace(/target="_self"/g, 'target="_blank"')
    setEditorState(value)
    input.onChange(parsed)
  }

  const mutateContentBlock = (selectionStateKey, focusOffSet, state, content) => {
    const changeAtomicBlock = Modifier.setBlockType(
      content,
      setSelectionAtTheBeginning(selectionStateKey, focusOffSet),
      'unstyled'
    )
    const removeAtomicBlock = Modifier.removeRange(
      changeAtomicBlock,
      setSelectionAtTheBeginning(selectionStateKey, focusOffSet),
      'backward'
    )
    let newEditorState = EditorState.push(state, removeAtomicBlock, 'remove-range')
    newEditorState = EditorState.forceSelection(newEditorState, setSelectionAtTheBeginning(selectionStateKey))
    handleEditorStateChange(newEditorState)
    return true
  }

  const handleKeyCommand = (cmd, state) => {
    const { selection, content } = getEditorAttr(state)
    const { startKey, endKey, startOffset, endOffset } = getSelectionAttr(selection)
    const contentBlock = content.getBlockForKey(startKey)
    const contentBlockType = contentBlock.getType()
    const contentBlockEmpty = !contentBlock.getLength()
    const beforeBlock = content.getBlockBefore(endKey)
    const contentBlockTypeDeletable = deletionAllowedTypes.includes(contentBlockType)
    const atTheBeginningOfStartBlock = startOffset === 0
    const emptySelection = isEmptySelection(startOffset, endOffset, startKey, endKey)

    if (cmd === 'backspace') {
      if (contentBlockTypeDeletable && (contentBlockEmpty || atTheBeginningOfStartBlock) && emptySelection)
        return mutateContentBlock(startKey, 0, state, content)

      if (beforeBlock?.getType() === 'atomic' && contentBlockEmpty) {
        const beforeKey = beforeBlock.getKey()
        return mutateContentBlock(beforeKey, 1, state, content)
      }

      if (contentBlockType === 'atomic') return mutateContentBlock(startKey, 1, state, content)

      return false
    }
    return false
  }

  const handleFocus = focus => {
    setFocused(focus)
    focus ? input.onFocus() : input.onBlur()
  }

  return (
    <div className={clsx('js-field-container', marginSizes[margin], className)}>
      {label && (
        <label
          className="block font-bold mb-3"
          id={`${name}-label`}
          htmlFor={`${name}-input`}
          aria-describedby={`${name}-hint`}
        >
          {label}
        </label>
      )}
      {hint && <p id={`${name}-hint`} className="mb-4" dangerouslySetInnerHTML={{ __html: hint }}></p>}
      <div className={clsx('relative', editorClassName)}>
        <Editor
          ref={refEditor}
          placeholder={setPlaceholder(editorState, placeholder)}
          wrapperClassName={clsx('wrapper-class', editorWrapperClassName)}
          toolbarClassName="toolbar-class"
          editorClassName={clsx('editor-class', allowScrolling ? 'max-h-40 min-h-40 overflow-y-auto' : 'min-h-24')}
          editorState={editorState}
          onEditorStateChange={handleEditorStateChange}
          handleBeforeInput={handleBeforeInput}
          handleReturn={handleReturn}
          handleKeyCommand={handleKeyCommand}
          onFocus={() => handleFocus(true)}
          onBlur={() => handleFocus(false)}
          handlePastedText={handlePastedText}
          ariaLabel={ariaLabel || label}
          toolbar={{
            options,
            inline: {
              inDropdown: false,
              options: ['bold', 'italic'],
              bold: { title: 'Negrita' },
              italic: { title: 'Cursiva' }
            },
            list: {
              inDropdown: false,
              options: ['unordered', 'ordered'],
              unordered: { title: 'Viñetas' },
              ordered: { title: 'Numeración' }
            },
            link: {
              inDropdown: false,
              options: ['link'],
              component: LinkToolBar,
              showOpenOptionOnHover: false,
              defaultTargetOption: '_blank'
            }
          }}
          toolbarCustomButtons={canUploadFiles && [<ImageToolBar key={0} />, <VideoToolBar key={1} />]}
          customBlockRenderFunc={(block, config) => customBlockRender(block, config, refEditor)}
        />
        <div className="flex gap-10 justify-between">
          <div>
            <Error name={name} />
            <CapsLockMessage evaluate={capsLockInfo && !gotError} focused={focused} value={htmlTextValue} />
          </div>
          {hasMaxContentLength && (
            <p className="font-normal text-sm mt-1 text-gray-700 text-right">
              {maxContentLength > 0 && `${getContentLength(editorState)}/${maxContentLength} caracteres`}
            </p>
          )}
        </div>
      </div>
    </div>
  )
}

HtmlField.propTypes = {
  canUploadFiles: PropTypes.bool,
  fullName: PropTypes.string,
  hint: PropTypes.string,
  initialValue: PropTypes.string,
  isBasic: PropTypes.bool,
  hasMaxContentLength: PropTypes.bool,
  label: PropTypes.string,
  margin: PropTypes.string,
  maxContentLength: PropTypes.number,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  validate: PropTypes.func,
  ariaLabel: PropTypes.string,
  editorClassName: PropTypes.string,
  editorWrapperClassName: PropTypes.string,
  className: PropTypes.string,
  allowScrolling: PropTypes.bool,
  capsLockInfo: PropTypes.bool
}
