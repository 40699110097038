import PropTypes from 'prop-types'
import React from 'react'
import TextField from '../../molecules/fields/TextField'
import CheckboxField from '../../molecules/fields/CheckboxField'
import Alert from '../../molecules/Alert'
import Error from '../../molecules/Error'
import * as validations from '../../../utils/validations'
import { groupFileTypes } from '../../../configs/fileTypesConfig'
import { HtmlField } from '../../molecules/fields/HtmlField'
import ManualEntryPayload from './ManualEntryPayload'

const FileField = ({ baseName, label }) => {
  return (
    <div className="grid md:grid-cols-2 gap-4">
      <div>
        {label ? (
          <p className="font-bold mb-6">{label}</p>
        ) : (
          <TextField
            label="Etiqueta de la pregunta"
            size="full"
            name={`${baseName}.payload.label`}
            validate={validations.required()}
            margin="none"
            errorClassName="relative inset-0"
            initialValue=""
            capsLockInfo
          />
        )}
        <HtmlField
          label="Descripción de la pregunta"
          margin="minimun"
          name={`${baseName}.payload.hint`}
          className="mt-4"
          capsLockInfo
        />
        <ManualEntryPayload baseName={baseName} margin="dense" size="full" />
      </div>

      <Alert type="info">
        Las personas podrán subir un archivo de hasta 10 MB. Se validará que el formato sea uno de los que seleccionaste
        como permitidos.
      </Alert>

      <div className="relative">
        <p className="font-bold mb-2">Formatos del archivo permitidos</p>
        <div>
          {groupFileTypes.map((type, key) => (
            <CheckboxField
              name={`${baseName}.payload.types`}
              label={type.label}
              value={type.type}
              key={key}
              margin="dense"
              validate={validations.requiredArray('Ingresa al menos una opción.', 1)}
              hideError
            />
          ))}
        </div>
        <Error className="absolute -bottom-4" name={`${baseName}.payload.types`} />
      </div>
    </div>
  )
}

export default FileField

FileField.propTypes = {
  baseName: PropTypes.string,
  label: PropTypes.string
}
