import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import TextField from '../../molecules/fields/TextField'
import * as validations from '../../../utils/validations'
import CheckboxField from '../../molecules/fields/CheckboxField'
import Value from '../../molecules/Value'
import { marginSizes } from '../../../configs/sizesConfig'
import clsx from 'clsx'
import { FormTypeContext } from '../../../utils/contexts'
import Hint from './Hint'
import WhenFieldChanges from '../../molecules/WhenFieldChanges'

const ManualEntryPayload = ({ baseName, margin = 'dense', size }) => {
  const typeForm = useContext(FormTypeContext)
  const hasManualEntryName = `${baseName}.manual_entry_payload.has_manual_entry`
  const labelName = `${baseName}.manual_entry_payload.label`
  if (typeForm !== 'procedure_management') return null
  return (
    <div className={clsx(marginSizes[margin])}>
      <CheckboxField
        name={hasManualEntryName}
        className="ml-0.5"
        margin="dense"
        label="Cambiar etiqueta para el formulario de registro de una solicitud por parte del personal de entidad"
        noYpadding
      />
      <WhenFieldChanges field={hasManualEntryName} set={labelName} to={null} />
      <Value name={hasManualEntryName}>
        {({ value }) =>
          value && (
            <div className="ml-8 flex flex-col items-start rounded bg-neutral-200 p-6">
              <TextField
                label="Etiqueta para la vista de Ingresar solicitud manual"
                name={labelName}
                margin="dense"
                validate={validations.required()}
                dataTestid="hintQuestion"
                size={size}
                rootClassName="w-full"
                capsLockInfo
              />
              <Hint baseName={`${baseName}.manual_entry_payload`} />
            </div>
          )
        }
      </Value>
    </div>
  )
}

export default ManualEntryPayload

ManualEntryPayload.propTypes = {
  baseName: PropTypes.string,
  margin: PropTypes.string,
  size: PropTypes.string
}
