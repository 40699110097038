import PropTypes from 'prop-types'
import React from 'react'
import TextField from '../../molecules/fields/TextField'
import Alert from '../../molecules/Alert'

const ReferToBranchOfficeField = ({ baseName, validate }) => {
  return (
    <div className="grid md:grid-cols-2 gap-4">
      <TextField
        label="Etiqueta de la pregunta"
        size="full"
        name={`${baseName}.payload.label`}
        validate={validate}
        margin="none"
        errorClassName="relative inset-0"
        capsLockInfo
      />
      <Alert>Al usuario le aparecerá una lista con todas las sedes de la entidad.</Alert>
    </div>
  )
}

export default ReferToBranchOfficeField

ReferToBranchOfficeField.propTypes = {
  baseName: PropTypes.string,
  validate: PropTypes.func
}
